import React, {useEffect} from "react";
import {Resolver, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {showSuccess} from "../../../../TNTFramwork/Helper/helper";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";
import {ModuleServices} from "../Services/Services";
import {FormFields, FormFieldsValidationSchema} from "../Config/InterfaceSetting";

export const ChangePassword = () => {


    const methods = useForm<FormFields>({
        resolver: yupResolver(FormFieldsValidationSchema) as Resolver<FormFields>,
    });

    const {handleSubmit, reset, control, formState: {errors}} = methods;
    useEffect(() => {
        if (Object.keys(errors).length !== 0){
            console.log(errors)
        }
    } , [errors])
    const onSubmit = async (data: any) => {
        delete data.repeatPassword
        const response = await ModuleServices.changePassword(data)
        if (response.status === 200){
            showSuccess(response.data.message)
            reset({
                password: '',
                repeatPassword: '',
            })
        }else {
            console.log(response)
        }
    }
    return(
       <>
           <div className={'userPanelContainer overflow-x-hidden h-full'}>
               <div className={'p-10 h-full relative'}>
                   <form onSubmit={handleSubmit(onSubmit)} className={'h-full '}>
                       <div className={'grid grid-auto-fit items-center h-full'}>
                           <div className={'flex flex-col items-center space-y-4 w-full'}>
                               <div className={'w-full sm:w-10/12 flex-grow flex-shrink-0'}>
                                   <TUInput label={'رمز عبور'} name={'password'} control={control} type={'password'}/>
                               </div>
                               <div className={'w-full sm:w-10/12 flex-grow flex-shrink-0'}>
                                   <TUInput label={'تکرار رمز عبور'} name={'repeatPassword'} control={control} type={'password'}/>
                               </div>
                               <button className={'bottom-0 bg-background-black rounded-full w-[344px] h-[72px]'}>
                                   <span className={'p-6 text-white text-base font-normal'}>تغییر رمز عبور</span>
                               </button>
                           </div>
                           <img src={'/image/changePassword.png'} className={''}/>

                       </div>

                   </form>
               </div>
           </div>
       </>
    )
}