import * as Yup from "yup";

export interface ITicketRequest {
    title?: string,
    text?: string,
    priority?: string,
    department?: string,
    file?: any,
}

export const defaultValues: ITicketRequest = {
    title: "",
    text: "",
    priority: "low",
    department: 'sales',
    file: null,
}


export const TicketSchema = Yup.object().shape({
    title: Yup.string()
        // .required('فیلد موضوع اجباری است')
    ,
    text: Yup.string()
        // .required('فیلد متن اجباری است')
    ,
    priority: Yup.string().required(),
    department: Yup.string().required(),
    file: Yup.mixed().defined().nullable()
        .test(
            "fileSize",
            "File Size is too large",
            value => {
                if (value instanceof File) { // Check if the value is an instance of File
                    return value.size <= 2000000 // 2MB
                }
                return true;
            }
        )
        .test(
            "fileFormat",
            "Unsupported Format",
            value => {
                if (value instanceof File) { // Check if the value is an instance of File
                    return ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
                }
                return true;
            }
        )
})
