import React from "react";
import {setGlobalContext} from "../../Config/Context/GlobalContext";

interface NotFoundProps {
    redirectLink?: string;
    buttonText?: string;
}

const  NotFound: React.FC<NotFoundProps> = ({
        redirectLink = '/customer-panel',
        buttonText = 'داشبورد',
    }) => {
        setGlobalContext((prevState) => ({
            ...prevState,
            variable:{
                ...prevState.variable , notfound: true
            }
        }));
        return(
            <main className="h-screen w-full flex flex-col justify-center items-center bg-gray-200">
                <h1 className="text-9xl font-extrabold text-[#1A2238] tracking-widest">404</h1>
                <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
                    صفحه پیدا نشد
                </div>
                <button className="mt-5">
                    <a href={redirectLink}
                        className="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
                    >
            <span
                className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"
            ></span>

            <span className="relative block px-8 py-3 bg-[#1A2238] border border-current text-gray-50">
                { buttonText }
            </span>
                    </a>
                </button>
            </main>
        )
}
export default NotFound;
