import { Option, Select } from "@material-tailwind/react";
import React, { useState } from "react";
import { Controller} from 'react-hook-form';

interface IItems {
    label: string,
    value: string
}

interface TUSelectProps {
    label: string;
    items?: IItems[];
    onChange?: (...args: any[]) => any;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    containerProps?: string;
    labelProps?: string;
    optionClassName?: string;
    selectFirstItem?: boolean;
    control?: any;
    name?: string;
}

export const TUSelect = ({
                             label,
                             items,
                             onChange: handleChange,
                             children,
                             className,
                             containerProps,
                             labelProps,
                             optionClassName,
                             selectFirstItem = false,
                             control = null,
                             name = ''
                         }: TUSelectProps) => {

  const [selectedValue, setSelectedValue] = useState(selectFirstItem && items && items.length > 0 ? items[0].value : "");
  const onChange = (event: any) => {
    setSelectedValue(event);
    if (handleChange) {
      handleChange(event);
    }
  };

    return (
        <>
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={selectFirstItem && items ? items[0].value : ""}
                    render={({ field }) => (
                        <Select
                            label={label}
                            variant="outlined"
                            offset={1}
                            className={`${className ?? ''}`}
                            containerProps={{ className: `theme-select ${containerProps ?? ''} ` }}
                            labelProps={{ className: `before:border-l-0 after:border-r-0  ${labelProps ?? ''}` }}
                            animate={{
                                mount: { y: 0 },
                                unmount: { y: 25 },
                            }}
                            onChange={value => {
                                if (value !== undefined) {
                                    field.onChange(value);
                                }
                            }}
                            arrow={
                                <div className={'flex gap-1 items-center'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                         className="w-5 chev-icon h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            }
                        >
                            {(items && items?.length > 0) ?
                                items?.map((item: any, index: number) => (
                                    <Option className={`${optionClassName ?? ''}`} value={item.value} key={index}>{item.label}</Option>
                                ))
                                :
                                children
                            }
                        </Select>
                    )}
                />
            ) : (
                <Select
                    label={label}
                    variant="outlined"
                    offset={1}
                    className={`${className ?? ''}`}
                    containerProps={{ className: `theme-select ${containerProps ?? ''} ` }}
                    labelProps={{ className: `before:border-l-0 after:border-r-0  ${labelProps ?? ''}` }}
                    animate={{
                        mount: { y: 0 },
                        unmount: { y: 25 },
                    }}
                    value={selectedValue}
                    onChange={onChange}
                    arrow={
                        <div className={'flex gap-1 items-center'}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                 className="w-5 chev-icon h-5">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                    }
                >
                    {(items && items?.length > 0) ?
                        items?.map((item: any, index: number) => (
                            <Option className={`${optionClassName ?? ''}`} value={item.value} key={index}>{item.label}</Option>
                        ))
                        :
                        children
                    }
                </Select>
            )}
        </>
    );
}