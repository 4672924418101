import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import React from "react";

const Toastify = () =>{
    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    )
}


export default Toastify