import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FastYouFundLoaderComponent } from "../SVG/FastYouFundLoader";

function TableReactTable({
                           columns,
                           data,
                           tableTitle,
                           children,
                           TableLoading,
                           emptyDataImageProp = "",
                           emptyDataTextProp = "رکوردی برای نمایش وجود ندارد",
                           emptyDataComponentProp
                         }:
                           { columns: any, data: any, tableTitle?: string, children: any, TableLoading: any,
                             emptyDataImageProp?: string, emptyDataTextProp?: string, emptyDataComponentProp?: any }) {
  const [hoveredRow, setHoveredRow] = useState<any>(null);
  const [emptyDataImage, setEmptyDataImage] = useState<any>();

  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow,
    page
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (emptyDataImageProp === "") {
      setEmptyDataImage(
        <>
          <span>{emptyDataTextProp}</span>
          {emptyDataComponentProp && (
            emptyDataComponentProp
          )}
        </>
      );
    } else {
      setEmptyDataImage(
        <>
          <span>{emptyDataTextProp}</span>
          <img src={emptyDataImageProp} width={200} className={"block m-auto"} />
          {emptyDataComponentProp && (
            emptyDataComponentProp
          )}
        </>
      );
    }
  }, []);

  return (
    <>
      {tableTitle && (
        <div className={"min-h-8"}>
          <div className={"p-2"}>
            <h1>{tableTitle}</h1>
          </div>
        </div>
      )}
      {TableLoading ? (
        <div className="flex justify-center my-[10%]">
              <FastYouFundLoaderComponent/>
        </div>
      ): (
      <div className="flex flex-col m-2 ">
        <div className="px-4 md:px-0 -my-2 overflow-x-auto pb-4">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={`${(data.length === 0 && emptyDataImageProp === "") ? "border-b border-gray-200 shadow" : ""} overflow-hidden sm:rounded-lg`}>
              {data.length === 0 ? (
                <div className={"text-center items-center justify-center py-10 pb-10 space-y-4"}>
                  {emptyDataImage}
                </div>
              ) : (
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, columnIndex) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={columnIndex}
                          scope="col"
                          className="py-3 text-center text-xs font-medium text-gray-500 tracking-wider w-fit whitespace-nowrap p-2 "
                        >
                          {column.render("Header")}
                          <span>
      {column.isSorted
        ? column.isSortedDesc
          ? " ▼"
          : " ▲"
        : ""}
    </span>
                        </th>
                      ))}

                    </tr>
                  ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {TableLoading ? (
                    <tr>
                      {columns.map((item: any, index: any) => (
                        <td key={index}>
                          <Skeleton count={8} className={"my-1 py-2 whitespace-nowrap text-center"} />
                        </td>
                      ))}
                    </tr>
                  ) : (
                    <>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <React.Fragment key={i}>
                            <tr
                              {...row.getRowProps()}
                              onMouseEnter={() => setHoveredRow(row)}
                              onMouseLeave={() => setHoveredRow(null)}
                              className={row === hoveredRow ? "bg-gray-100" : ""}
                            >
                              {row.cells.map((cell, cellIndex) => (
                                <td
                                  {...cell.getCellProps()}
                                  key={cellIndex}
                                  className="p-2 whitespace-nowrap text-center text-black font-normal text-sm"
                                >
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {children &&
          <div className={`-mx-4 sm:-mx-6 lg:-mx-8 -mb-2 `}>
            {children}
          </div>

        }
      </div>
        )}
    </>
  );
}

export default TableReactTable;