export const SVGDollar = ({className}: { className?: string }) => {
    return (
        <>
            <svg className={className ?? ``} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.4104 6.03984L14.8958 11.1315C11.7812 12.2961 9.23535 15.9794 9.23535 19.2836V39.4065C9.23535 42.6023 11.3479 46.8003 13.9208 48.7232L25.5666 57.4169C29.3854 60.2878 35.6687 60.2878 39.4874 57.4169L51.1333 48.7232C53.7062 46.8003 55.8187 42.6023 55.8187 39.4065V19.2836C55.8187 15.9523 53.2729 12.269 50.1583 11.1044L36.6437 6.03984C34.3416 5.20026 30.6583 5.20026 28.4104 6.03984Z" fill="#E4E4E4" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M35.6843 34.944C35.0536 35.5453 34.115 35.9267 32.8683 36.088V37.804H31.1963V36.198C29.7443 36.1393 28.5563 35.9193 27.6323 35.538V33.954C28.9083 34.4527 30.2136 34.702 31.5483 34.702C32.5896 34.702 33.3816 34.5187 33.9243 34.152C34.4816 33.7707 34.7603 33.206 34.7603 32.458C34.7603 32.0033 34.643 31.622 34.4083 31.314C34.1883 31.006 33.829 30.742 33.3303 30.522C32.8463 30.302 32.1716 30.082 31.3063 29.862C30.2943 29.5833 29.5023 29.29 28.9303 28.982C28.3583 28.6593 27.9476 28.2707 27.6983 27.816C27.4636 27.3613 27.3463 26.782 27.3463 26.078C27.3463 25.022 27.6616 24.1787 28.2923 23.548C28.9376 22.9173 29.9056 22.536 31.1963 22.404V20.732H32.8683V22.382C33.4696 22.4113 34.0416 22.47 34.5843 22.558C35.1416 22.646 35.5743 22.7487 35.8823 22.866V24.45C34.841 24.054 33.7556 23.856 32.6263 23.856C31.6583 23.856 30.8443 24.01 30.1843 24.318C29.539 24.6113 29.2163 25.1907 29.2163 26.056C29.2163 26.4667 29.3116 26.8113 29.5023 27.09C29.7076 27.3687 30.0376 27.6107 30.4923 27.816C30.9616 28.0213 31.6143 28.2193 32.4503 28.41C33.5503 28.674 34.401 28.9893 35.0023 29.356C35.6183 29.708 36.0436 30.1333 36.2783 30.632C36.5276 31.116 36.6523 31.7173 36.6523 32.436C36.6523 33.492 36.3296 34.328 35.6843 34.944Z"
                    fill="#B4B4B4"/>
            </svg>
        </>
    )
}
export const SVGStar = ({className}: { className?: string }) => {
    return (
        <>
            <svg className={className ?? ``} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_449_11709)">
                    <path d="M32.5 0L41.278 23.722L65 32.5L41.278 41.278L32.5 65L23.722 41.278L0 32.5L23.722 23.722L32.5 0Z" fill="#E4E4E4"/>
                    <path d="M32.5 2.88154L40.3401 24.0691L40.4997 24.5003L40.9309 24.6599L62.1185 32.5L40.9309 40.3401L40.4997 40.4997L40.3401 40.9309L32.5 62.1185L24.6599 40.9309L24.5003 40.4997L24.0691 40.3401L2.88154 32.5L24.0691 24.6599L24.5003 24.5003L24.6599 24.0691L32.5 2.88154Z" stroke="#D9D9D9" strokeWidth="2"/>
                </g>
                <defs>
                    <filter id="filter0_b_449_11709" x="-60" y="-60" width="185" height="185" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="30"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_449_11709"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_449_11709" result="shape"/>
                    </filter>
                </defs>
            </svg>

        </>
    )
}

export const SVGPerson = ({className}: { className?: string }) => {
    return (
        <>
            <svg className={className ?? ``} width="45" height="63" viewBox="0 0 45 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.648 28.7016C22.3437 28.6712 21.9784 28.6712 21.6436 28.7016C14.3996 28.4581 8.64697 22.5227 8.64697 15.2176C8.64697 7.76028 14.6735 1.70312 22.161 1.70312C29.6181 1.70312 35.6751 7.76028 35.6751 15.2176C35.6447 22.5227 29.8921 28.4581 22.648 28.7016Z" fill="#E4E4E4" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.4301 39.9325C0.0643169 44.8634 0.0643169 52.8991 7.4301 57.7996C15.8003 63.4002 29.5274 63.4002 37.8976 57.7996C45.2634 52.8686 45.2634 44.833 37.8976 39.9325C29.5579 34.3623 15.8307 34.3623 7.4301 39.9325Z" fill="#E4E4E4" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    )
}

export const SVGSinus = ({className}: { className?: string }) => {
    return (
        <>
            <svg className={`w-full ${className ?? ``}`}  viewBox="0 0 258 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_449_11717)">
                    <path d="M48 54.0979L50.4697 56.7166C52.9394 59.3354 57.8788 64.5728 62.8182 68.8725C67.7576 73.1722 72.697 76.5341 77.6364 74.2844C82.5758 72.0348 87.5152 64.1736 92.4545 58.6604C97.3939 53.1473 102.333 49.9821 107.273 49.1867C112.212 48.3912 117.152 49.9654 122.091 57.2744C127.03 64.5833 131.97 77.627 136.909 84.3258C141.848 91.0246 146.788 91.3786 151.727 89.3276C156.667 87.2766 161.606 82.8206 166.545 77.7396C171.485 72.6585 176.424 66.9524 181.364 64.7238C186.303 62.4951 191.242 63.744 196.182 62.7252C201.121 61.7065 206.061 58.4201 208.53 56.7769L211 55.1337" stroke="url(#paint0_linear_449_11717)" strokeWidth="10"/>
                </g>
                <g filter="url(#filter1_d_449_11717)">
                    <path d="M48 53.0979L50.4697 55.7166C52.9394 58.3354 57.8788 63.5728 62.8182 67.8725C67.7576 72.1722 72.697 75.5341 77.6364 73.2844C82.5758 71.0348 87.5152 63.1736 92.4545 57.6604C97.3939 52.1473 102.333 48.9821 107.273 48.1867C112.212 47.3912 117.152 48.9654 122.091 56.2744C127.03 63.5833 131.97 76.627 136.909 83.3258C141.848 90.0246 146.788 90.3786 151.727 88.3276C156.667 86.2766 161.606 81.8206 166.545 76.7396C171.485 71.6585 176.424 65.9524 181.364 63.7238C186.303 61.4951 191.242 62.744 196.182 61.7252C201.121 60.7065 206.061 57.4201 208.53 55.7769L211 54.1337" stroke="url(#paint1_linear_449_11717)" strokeWidth="1.5"/>
                </g>
                <defs>
                    <filter id="filter0_f_449_11717" x="0.362305" y="0" width="257.407" height="139.432" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="22" result="effect1_foregroundBlur_449_11717"/>
                    </filter>
                    <filter id="filter1_d_449_11717" x="36.4546" y="40.25" width="185.961" height="64.9316" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="5.5"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_449_11717"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_449_11717" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_449_11717" x1="33" y1="26.3935" x2="237.809" y2="54.5161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2D58ED"/>
                        <stop offset="0.557292" stopColor="#7DDDE4"/>
                        <stop offset="0.934318" stopColor="#FF6B6B"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_449_11717" x1="33" y1="36.5" x2="227.5" y2="89" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2D58ED"/>
                        <stop offset="0.557292" stopColor="#7DDDE4"/>
                        <stop offset="0.934318" stopColor="#FF6B6B"/>
                    </linearGradient>
                </defs>
            </svg>

        </>
    )
}


export const SVGSearchIcon = ({className}:{className?:string})=>{
    return <svg className={className??'fill-black'} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 22.25C5.85 22.25 1.25 17.65 1.25 12C1.25 6.35 5.85 1.75 11.5 1.75C17.15 1.75 21.75 6.35 21.75 12C21.75 17.65 17.15 22.25 11.5 22.25ZM11.5 3.25C6.67 3.25 2.75 7.18 2.75 12C2.75 16.82 6.67 20.75 11.5 20.75C16.33 20.75 20.25 16.82 20.25 12C20.25 7.18 16.33 3.25 11.5 3.25Z" />
        <path d="M22 23.2504C21.81 23.2504 21.62 23.1804 21.47 23.0304L19.47 21.0304C19.18 20.7404 19.18 20.2604 19.47 19.9704C19.76 19.6804 20.24 19.6804 20.53 19.9704L22.53 21.9704C22.82 22.2604 22.82 22.7404 22.53 23.0304C22.38 23.1804 22.19 23.2504 22 23.2504Z" />
    </svg>

}