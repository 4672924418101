import React from "react";
import {PlanTable} from "../../../../Site/components/Shared/Plan/PlanTable";


function List() {
    // const load = async () => {
    //     await ModuleServices.getList().then((response) => {
    //         setRow(response.data.data.data)
    //         setPaginateData(response.data.data)
    //         setLoading(false)
    //     })
    // }
    //
    // useEffect(() => {
    //     load()
    // }, []);


    return (
        <>
            <div className={'userPanelContainer overflow-x-hidden'}>
                <PlanTable />
            </div>
        </>
    )
}

export default List