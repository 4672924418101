import React from "react";
import {Rating} from "@material-tailwind/react";

export const TicketConversationAdminAnswer = ({text, time, admin} : {text: string, time: string, admin: any}) => {
    return (
        <div className={'relative bg-white shadow-lg text-black rounded-2xl'}>
            <div className={'flex justify-between'}>
                <div className={'flex items-center justify-start pt-4 px-6'}>
                    <img src={admin.avatar} className="rounded-full w-[51px] h-[51px]" />
                    <div className={'pr-2'}>
                        <p>{admin.name}</p>
                        <p className={'text-sm'}>{time}</p>
                    </div>
                </div>
                <div className={'pt-4 px-6'}>
                    <p className={`text-sm md:text-normal`}>امتیاز دهید</p>
                    <Rating />
                </div>
            </div>
            <div className={'p-3'}>
                <p className={'text-sm leading-7 font-semibold'} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div
                className="absolute -left-1 top-1/2 transform  rotate-45 w-2 h-2 bg-white"></div>
        </div>
    )
}