import React, {useEffect, useState} from "react";
import TableReactTable from "../../../../TNTFramwork/Components/Shared/TableReactTable";
import {PaginationSimple} from "../../../../TNTFramwork/Components/Shared/Pagination/PaginationSimple";
import {IPaginate} from "../../../../TNTFramwork/Components/Shared/Pagination/Interfaces";
import {ModuleServices} from "../Services/Services";
import {dateFormatter} from "../../../../TNTFramwork/Helper/DateHelper";
import { router } from "../../../../Constant/routes";
import { Link, NavLink } from 'react-router-dom';


function List() {
    const [row, setRow] = useState([])
    const [paginateData, setPaginateData] = useState<IPaginate>()
    const [TableLoading, setLoading] = useState(true)
    const load = async () => {
        await ModuleServices.getList().then((response) => {
          console.log(response.data.data)
            setRow(response.data.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        load()
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "نام پکیج",
                accessor: "package",
            },
            {
                Header: 'شماره حساب',
                accessor: "username",
                disableSortBy: true
            },
            {
                Header: 'رمز ورود',
                accessor: "password",
                disableSortBy: true
            },
            {
                Header: 'مرحله اکانت',
                accessor: "flowState",
                disableSortBy: true,
                Cell: ({cell}: { cell: any }) => {
                    if (cell.row.original.flowState === 'test_step_1'){
                        return(
                              <span>چالش مرحله اول</span>
                        )
                    }
                  if (cell.row.original.flowState === 'test_step_2'){
                    return(
                      <span>چالش مرحله دوم</span>
                    )
                  }
                  if (cell.row.original.flowState === 'test_step_3'){
                    return(
                      <span>اکانت واقعی</span>
                    )
                  }
                }
            },
            {
                Header: 'زمان ایجاد اکانت',
                accessor: "metaTraderAccountCreateTime",
                disableSortBy: true,
                Cell: ({cell}: { cell: any }) => {
                    return(
                        <span>{dateFormatter(cell.row.original.metaTraderAccountCreateTime)}</span>
                    )
                }
            },
            {
                Header: 'زمان آغاز چالش',
                accessor: "challengeCreateTime",
                disableSortBy: true,
                Cell: ({cell}: { cell: any }) => {
                    return(
                        <span>{dateFormatter(cell.row.original.challengeCreateTime)}</span>
                    )
                }
            },
            {
                Header: "آنالیز حساب",
                accessor: "analyze",
                Cell: ({cell}: { cell: any }) => {
                    return(
                      <Link to={'https://analyze.coretrades24.com/123123/10/20/23/23/34'} target={'_blank'}
                            className={'block w-full bg-gray-900 px-2 py-2 text-white rounded'}>نمایش</Link>
                    )
                }
            },
        ],
        []
    );

    return (
        <>
            <div className={'userPanelContainer overflow-x-hidden'}>
                <div className={'userPanelContainerHeader'}>
                    <span className={'font-bold text-lg'}>اکانت های فعال من</span>
                </div>
                <TableReactTable columns={columns} data={row} TableLoading={TableLoading}
                                 emptyDataImageProp={'/image/NoTransaction.png'}
                                 emptyDataTextProp={'اکانت فعالی یافت نشد'}
                                 emptyDataComponentProp={
                                   <NavLink to={router.customerPanelLink.package.list} className={"text-gray-100"}>
                                     <button className={"w-72 bg-background-black py-4 px-2 font-bold text-base rounded-full my-2"}>
                                       خرید پکیج جدید
                                     </button>
                                   </NavLink>
                                 }
                    // tableTitle={'لیست سرویس های من'}
                >
                    {(paginateData) && (
                        <PaginationSimple paginationData={paginateData} setRow={setRow}
                                          setPaginateData={setPaginateData}/>
                    )}
                </TableReactTable>
            </div>
        </>
    )
}

export default List