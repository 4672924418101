import {NavLink} from "react-router-dom";
import {SVGArrowRight} from "../SVG/SVGArrow";
import {DataItem} from "../../Config/interfaces/BlogInterface";
import { Helmet } from 'react-helmet';

export const BlogContent = ({blogData}:{blogData:DataItem}) => {
    const locale = `fa`;

    return (
        <>
            <Helmet>
                <title>{blogData?.metaTitle}</title>
                <meta name="title" content={blogData?.metaTitle}/>
                <meta name="description" content={blogData?.metaDescription}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:title" content={blogData?.metaTitle}/>
                <meta property="og:description"
                      content={blogData?.metaDescription}/>
                <meta property="og:image"
                      content={blogData?.thumb}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={window.location.href}/>
                <meta property="twitter:title"
                      content={blogData?.metaTitle}/>
                <meta property="twitter:description"
                      content={blogData?.metaDescription}/>
                <meta property="twitter:image"
                      content={blogData?.thumb}/>
            </Helmet>

            <div className={`bg-white rounded-3xl shadow rounded-3xl p-4 `}>
                <NavLink className={`text-blue-600 text-sm font-bold`} to={`/${locale}/blog-list`}><SVGArrowRight className={`inline-block`} /> بازگشت به همه </NavLink>
                <h1 className={'font-bold text-[1.75rem] mt-4 mb-2'}>{blogData?.name}</h1>
                <article className={'leading-8 text-gray-700'} dangerouslySetInnerHTML={{__html:blogData?.text}}></article>
            </div>
        </>
    )
}