import {BlogHeader} from "../components/Blog/BlogHeader";
import {BlogContent} from "../components/Blog/BlogContent";
import {getAPI} from "../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {DataItem, ICategoryBlog, IResponseBlog} from "../Config/interfaces/BlogInterface";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export const BlogDetails = () => {
    const {slug} = useParams()
    const [category, setCategory] = useState<ICategoryBlog[]>([])
    const [blogData, setBlogData] = useState<DataItem>()

    const load = async () => {
        const url = `/blog/blog/${slug}`
        await getAPI(url, '', false).then((response) => {
            const responseData: IResponseBlog = response.data.data
            setCategory(responseData.Categories)
            setBlogData(response.data.data.db as DataItem)
        })
    }

    useEffect(() => {
        load()
    }, [])


    document.querySelector('body')!.classList.add('bg-gradient-fade');

    return (
        <>
            <div className={`w-full mx-auto lg:container `}>
                <nav className={``}>
                    <BlogHeader category={category}/>
                </nav>
                <div className={`mt-10 bg-circle-double-fade before:left-[65vw] before:top-0 before:w-[30vw] before:h-[30vw] before:opacity-25 after:left-4 after:top-[50%] after:w-[30vw] after:h-[30vw] after:opacity-25`}>
                    <BlogContent blogData={blogData!} />
                </div>
            </div>

        </>
    )
}