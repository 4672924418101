import { useEffect, useState } from "react";
import { postAPI } from "../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import "../../successPayment.css";
import "../../failedPayment.css";
import { router } from "../../Constant/routes";
import { NavLink } from "react-router-dom";

interface IResponse {
  isSuccess: boolean;
  message: string;
  refID?: string | null;
  status: number;
}

export const BackPayment = () => {
  const params = new URLSearchParams(window.location.search);
  // eslint-disable-next-line
  const [authority, setAuthority] = useState(params.get("authority") || "");
  const [data, setData] = useState<IResponse>();

  const verifyTransaction = async () => {
    if (authority) {
      await postAPI("/paypoolVerify", { Authority: authority }).then((response) => {
        setData(response.data.data);
      });

    }
  };

  useEffect(() => {
    verifyTransaction();
  }, []);


  return (
    <>
      <div className={"container my-12  !max-w-[500px]"}>
        <div className={"row"}>
          <div className={"col-12 text-center"}>

            {(data) && (data.isSuccess) ? (
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            ) : (
              <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg" className="w-24 mx-auto"
                   xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Group-2" transform="translate(2.000000, 2.000000)">
                    <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" strokeWidth="4" cx="41.5" cy="41.5"
                            r="41.5"></circle>
                    <circle className="ui-error-circle" stroke="#F74444" strokeWidth="4" cx="41.5" cy="41.5"
                            r="41.5"></circle>
                    <path className="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line"
                          stroke="#F74444" strokeWidth="3" strokeLinecap="square"></path>
                    <path className="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line"
                          stroke="#F74444" strokeWidth="3" strokeLinecap="square"></path>
                  </g>
                </g>
              </svg>
            )}

            {(data) && (data.isSuccess) ? (
              <>
                <p className={"py-4 text-gray-800 font-bold text-[40px] leading-[45px]"}>پرداخت موفق !</p>
                <p className={"text-gray-900 text-base text-justify leading-7"}>پرداخت شما با موفقیت انجام شده است،
                  ایمیلی حاوی
                  اطلاعات ورود به متاتریدر برای شما ارسال شده است. در صورتی که ایمیل را دریافت نکرده‌اید با پشتیبانی
                  تماس
                  بگیرید.</p>
              </>
            ) : (
              <>
                <p className={"py-4 text-gray-800 font-bold text-[40px] leading-[45px]"}>خطا در پرداخت !</p>
                <p className={"text-gray-900 text-base text-justify leading-7"}>
                  مشکلی در پرداخت شما مشاهده شد، لطفا مجددا تلاش کنید، در صورتی که همچنان خطا دریافت کردید لطفا با
                  پشتیبانی تماس بگیرید
                </p>
              </>
            )}

            {(data) && (data.isSuccess) && (
              <div className={"flex justify-between py-5 mx-32"}>
                <span className={"font-bold"}>کد پیگیری</span>
                <span className={"font-bold"}>456465231</span>
              </div>
            )}
            {(data) && (data.isSuccess) ? (
              <>
                <NavLink to={router.customerPanelLink.accounts.list} className={"text-gray-100"}>
                  <button className={"w-full bg-background-black py-4 px-2 font-bold text-base rounded-full my-2"}>
                    مدیریت اکانت ها
                  </button>
                </NavLink>
                <NavLink to={"/fa" + router.siteLink.blog.list} className={"text-gray-100"}>
                  <button className={"w-full bg-background-black py-4 px-2 font-bold text-base rounded-full my-2"}>
                    صفحه آموزش ها
                  </button>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to={router.customerPanelLink.package.list} className={"text-gray-100"}>
                  <button className={"w-full bg-background-black py-4 px-2 font-bold text-base rounded-full my-2 mt-4"}>
                    تماس با پشتیبانی
                  </button>
                </NavLink>
                <NavLink to={router.customerPanelLink.tickets.new} className={"text-gray-100"}>
                  <button className={"w-full bg-background-black py-4 px-2 font-bold text-base rounded-full my-2"}>
                    خرید پکیج
                  </button>
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
