import {showAvatar} from "../../../../TNTFramwork/Helper/helper";
import React from "react";
import {useGlobalContext} from "../../../../TNTFramwork/Config/Context/GlobalContext";

export const TicketConversationUserQuestion = ({text, time} : {text: string, time: string}) => {
    const { config} = useGlobalContext();
    const { user } = config;
    return (
        <div className={'relative bg-blue-600 text-white rounded-2xl'}>
            <div className={'flex items-center justify-start pt-4 px-6'}>
                <img src={showAvatar(user.avatar)} className="rounded-full w-[51px] h-[51px]" />
                <div className={'pr-2'}>
                    <p>{user.name}</p>
                    <p className={'text-sm'}>{time}</p>
                </div>
            </div>
            <div className={'p-3'}>
                <p className={'text-sm leading-7 font-semibold'}>{text}</p>
            </div>
            <div
                className="absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-blue-600"></div>
        </div>
    )
}