import {useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {router} from "../../../../Constant/routes";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";

export function LoginStepPassword(props: any) {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const handleLogin = async (event: any) => {
        setLoading(true)
        event.preventDefault()
        try {
            const response = await postAPI('/loginStepPassword', {email: props.email , password}, setLoading);
            if (response.status == 200) {
                navigate(router.customerPanelLink.dashboard)
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleSetPassword = (e: any) => {
        setPassword(e.target.value)
    }

    const handleStep = (event: any) => {
        event.preventDefault()
        props.goToStep(1)
    }

    return (
        <>
            <div className={'w-full'}>
                <div className={'text-white md:text-gray-600 flex '}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="currentColor" className="w-8 h-8 md:hidden">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                    <div className={'flex items-center'}>
                        <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'}
                             height={'39px'}/>
                        <h1 className={'px-2 text-black font-extrabold uppercase'}>Core Trades</h1>
                    </div>
                </div>

                <div className={'mt-10 text-[#292D34]'}>
                    <span className={'text-xl font-bold leading-5'}>رمز عبور اکانت</span>
                </div>
                <div className={'mt-3 text-[#000000B2]'}>
                    <p className={'text-sm font-normal leading-5'}>برای ورود به داشبورد رمز خود را وارد کنید</p>
                    <div className={'flex gap-2 mt-2 items-center'}>
                        <span className={'text-sm font-normal leading-5'}> ایمیل ثبت شده: </span>
                        <span className={'text-sm font-normal leading-5'}>{props.email}</span>
                        <button className={'border rounded-full border-gray-600'} onClick={handleStep}><span className={'text-sm p-3'}>تغییر ایمیل</span></button>
                    </div>
                </div>

                <div className={'mt-8 mx-6'}>
                    <TUInput label={'رمز عبور'} type={'password'} onChange={handleSetPassword}/>
                </div>

                <div className={'flex justify-center mx-16 mt-8'}>
                    <button
                        onClick={handleLogin}
                        className={'flex justify-center items-center rounded-full border-2 w-full py-6 bg-black'}>
                        <span className={'text-white text-base font-bold'}>ادامه</span>
                    </button>
                </div>

                <div className={'flex justify-start mt-8'}>
                    <NavLink to={router.customerPanelLink.forgotPassword} className={"text-gray-100"}>
                        <span className={'text-blue-600 text-sm'}>رمز عبور را فراموش کرده اید؟</span>
                    </NavLink>
                </div>
            </div>
        </>
    )
}