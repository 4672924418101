import {Route, Routes} from 'react-router-dom';
import NotFound from "../../../../TNTFramwork/Components/Pages/NotFound";
import List from "../Pages/List";

export function RouterTransaction(){
    return (
        <Routes>
            <Route index element={<List />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
