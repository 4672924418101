import React from "react";

export const PlatformCard = ({title, className, platforms}: { title: string, className: string, platforms: any }) => {
    return (
        <>
            <div className={`w-full xs:w-1/2 sm:!w-2/5 lg:!w-1/4 p-2 ${className}`}>
                <div className={`bg-white rounded-3xl  border border-gray-200 text-black`}>
                    <div className={'m-6 mb-2'}>
                        {title}
                    </div>
                    <div className={'flex justify-center mt-2'}>
                        <img src={'/image/platforms/windows.png'} width={'80px'} height={'80px'}
                             alt={'download meta trader'}/>
                    </div>
                    <div className={'pb-6'}>
                        {platforms.map((item: any, index: number) => (
                            <div className={'px-4 pt-2'} key={index}>
                                {(item.label) ?
                                    <div className={'flex justify-between  bg-[#EBEBEB] items-center py-4 px-6 rounded-3xl'}>
                                        <a href={item.link} target={'_blank'} rel="noreferrer"
                                           className={'bg-btn-blue text-white px-6 py-2.5 rounded-full '}>دانلود</a>
                                        <span>{item.label}</span>
                                    </div>
                                :
                                    <div className={'flex justify-center  items-center py-4 px-6 rounded-3xl'}>
                                        <a href={item.link} target={'_blank'} rel="noreferrer"
                                           className={'bg-btn-blue text-white px-6 py-2.5 rounded-full '}>دانلود</a>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}