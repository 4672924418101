export const FastYouFundLoader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-300 opacity-2">
            <div className="relative w-24 h-24">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
                     className="loader">
                    <path
                        d="M479.7,216.07,283.93,20.3a48,48,0,0,0-67.86,0h0a48,48,0,0,0,0,67.86l26.22,26.22a62.69,62.69,0,0,1,0,88.65l-52.83,52.83-5.86,5.86a35.21,35.21,0,0,1-49.79,0h0a35.2,35.2,0,0,1,0-49.8l55.65-55.66a38.65,38.65,0,0,0,0-54.67h0a38.66,38.66,0,0,0-54.68,0L20.3,216.07a48,48,0,0,0,0,67.86L216.07,479.7a48,48,0,0,0,67.86,0h0a48,48,0,0,0,0-67.86l-26.22-26.22a62.69,62.69,0,0,1,0-88.65l52.83-52.83,5.86-5.86a35.21,35.21,0,0,1,49.79,0h0a35.2,35.2,0,0,1,0,49.8l-55.65,55.66a38.66,38.66,0,0,0,0,54.68h0a38.68,38.68,0,0,0,54.68,0L479.7,283.93A48,48,0,0,0,479.7,216.07Z"></path>
                </svg>
            </div>
        </div>
    )
}

export const FastYouFundLoaderComponent = () => {
  return (
      <div className="relative w-24 h-24">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
             className="loader">
          <path
            d="M479.7,216.07,283.93,20.3a48,48,0,0,0-67.86,0h0a48,48,0,0,0,0,67.86l26.22,26.22a62.69,62.69,0,0,1,0,88.65l-52.83,52.83-5.86,5.86a35.21,35.21,0,0,1-49.79,0h0a35.2,35.2,0,0,1,0-49.8l55.65-55.66a38.65,38.65,0,0,0,0-54.67h0a38.66,38.66,0,0,0-54.68,0L20.3,216.07a48,48,0,0,0,0,67.86L216.07,479.7a48,48,0,0,0,67.86,0h0a48,48,0,0,0,0-67.86l-26.22-26.22a62.69,62.69,0,0,1,0-88.65l52.83-52.83,5.86-5.86a35.21,35.21,0,0,1,49.79,0h0a35.2,35.2,0,0,1,0,49.8l-55.65,55.66a38.66,38.66,0,0,0,0,54.68h0a38.68,38.68,0,0,0,54.68,0L479.7,283.93A48,48,0,0,0,479.7,216.07Z"></path>
        </svg>
      </div>
  )
}