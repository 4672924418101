import {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {router} from "../../../../Constant/routes";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";
import {useGoogleLogin} from '@react-oauth/google';
import { useGlobalContext } from "../../../../TNTFramwork/Config/Context/GlobalContext";


export function LoginStepOne(props: any) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const {updateUser} = useGlobalContext();

    const handleSetEmail = (e: any) => {
        setEmail(e.target.value)
    }
    const handleNextStep = async (event: any) => {
        setLoading(true)
        event.preventDefault()
        try {
            const response = await postAPI('/loginStepOne', {email}, setLoading);
            if (response.status == 200) {
                localStorage.setItem('token', response.data.data.token)
                props.parentSetEmail(email)
                if (response.data.data.otp){
                    if (response.data.data.firstName === ' '){
                        props.goToStep(3)
                    }else {
                        props.goToStep(4)
                    }
                }else {
                    props.goToNextStep()
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const setToken = async (token: string) => {
        localStorage.setItem('token', token)
        console.log(localStorage.getItem('token') , 'func')
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const {access_token} = tokenResponse;
            try {
                const response = await postAPI('/loginWithGoogle', {access_token}, setLoading);
                if (response.status == 200) {
                    const data = response.data.data
                    await setToken(data.token)
                    // localStorage.setItem('token', data.token)
                    console.log(localStorage.getItem('token') , 'pure')
                    updateUser({
                        name: data.firstName + ' ' + data.lastName,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        referralCode: data.referralCode,
                        email: data.email,
                        phone: data.phone,
                        balance: data.balance,
                        avatar: data.avatar,
                    })
                    navigate(router.customerPanelLink.dashboard)
                    // showSuccess(response.data.message)
                }
            } catch (error) {
                console.error(error);
            }
        },
    });

    return (
        <>

            <div className={'w-full'}>
                <div className={'text-white md:text-gray-600 flex '}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="currentColor" className="w-8 h-8 md:hidden">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                    <NavLink to={'/'} className={'flex items-center'}>
                        <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'}
                             height={'39px'}/>
                        <h1 className={'px-2 text-black font-extrabold uppercase'}>Core Trades</h1>
                    </NavLink>
                </div>
                <div className={'mt-10 text-[#292D34]'}>
                    <span className={'text-xl font-bold leading-5'}>ورود</span>
                </div>
                <div className={'mt-3 text-[#000000B2]'}>
                    <span className={'text-sm font-normal leading-5'}>لطفاً اطلاعات لازم را در زیر برای دسترسی به داشبورد خود ارائه دهید.</span>
                </div>
                <div className={'flex justify-center mx-16 mt-8'}>
                    <button
                        onClick={() => {
                            googleLogin()
                        }}
                        className={'flex justify-center items-center rounded-full border-2 w-full py-6 gap-8'}>
                        <span className={'text-gray-900 text-base font-bold'}>با گوگل وارد شوید</span>
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_449_7752)">
                                <mask id="mask0_449_7752" maskUnits="userSpaceOnUse" x="0" y="0"
                                      width="23"
                                      height="22">
                                    <path d="M22.3845 0.0100098H0.394531V22H22.3845V0.0100098Z"
                                          fill="white"/>
                                </mask>
                                <g mask="url(#mask0_449_7752)">
                                    <path
                                        d="M21.5543 9.09333L12.5847 9.0929C12.1887 9.0929 11.8677 9.4139 11.8677 9.80998V12.6754C11.8677 13.0713 12.1887 13.3925 12.5847 13.3925H17.6359C17.0827 14.8278 16.0504 16.03 14.7333 16.7937L16.8871 20.5221C20.342 18.524 22.3846 15.0181 22.3846 11.0935C22.3846 10.5346 22.3434 10.1352 22.261 9.68538C22.1984 9.34363 21.9017 9.09333 21.5543 9.09333Z"
                                        fill="#167EE6"/>
                                    <path
                                        d="M11.3896 17.6976C8.91772 17.6976 6.75979 16.347 5.6008 14.3485L1.87256 16.4974C3.76984 19.7857 7.32406 22 11.3896 22C13.3841 22 15.2659 21.4631 16.8871 20.5272V20.5221L14.7334 16.7937C13.7481 17.365 12.6082 17.6976 11.3896 17.6976Z"
                                        fill="#12B347"/>
                                    <path
                                        d="M16.8871 20.5272V20.5221L14.7333 16.7937C13.7482 17.365 12.6083 17.6977 11.3896 17.6977V22C13.384 22 15.2661 21.4631 16.8871 20.5272Z"
                                        fill="#0F993E"/>
                                    <path
                                        d="M4.69692 11.005C4.69692 9.78654 5.02944 8.64662 5.6007 7.6615L1.87246 5.51257C0.931482 7.12863 0.394531 9.00543 0.394531 11.005C0.394531 13.0046 0.931482 14.8813 1.87246 16.4974L5.6007 14.3484C5.02944 13.3633 4.69692 12.2234 4.69692 11.005Z"
                                        fill="#FFD500"/>
                                    <path
                                        d="M11.3896 4.3124C13.0016 4.3124 14.4822 4.88517 15.6387 5.83792C15.9239 6.07293 16.3387 6.05597 16.6 5.79463L18.6302 3.76441C18.9268 3.4679 18.9056 2.98253 18.5888 2.70773C16.6512 1.02675 14.1301 0.0100098 11.3896 0.0100098C7.32406 0.0100098 3.76984 2.22434 1.87256 5.51262L5.6008 7.66154C6.75979 5.66299 8.91773 4.3124 11.3896 4.3124Z"
                                        fill="#FF4B26"/>
                                    <path
                                        d="M15.6387 5.83792C15.924 6.07293 16.3387 6.05597 16.6001 5.79463L18.6303 3.76441C18.9267 3.4679 18.9056 2.98253 18.5889 2.70773C16.6513 1.02671 14.1301 0.0100098 11.3896 0.0100098V4.3124C13.0015 4.3124 14.4822 4.88517 15.6387 5.83792Z"
                                        fill="#D93F21"/>
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_449_7752">
                                    <rect width="21.99" height="21.99" fill="white"
                                          transform="translate(0.394531 0.0100098)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
                <div className={'mx-6'}>
                    <div
                        className={' before:block before:h-[2px] items-center before:flex-grow-[5] before:bg-gray-300 after:block after:h-[2px] after:flex-grow-[5] after:bg-gray-300 flex w-full mt-4 mx-auto'}>
                        <p className={'px-[1px] flex-grow-[1] text-center'}>یا</p>
                    </div>
                </div>

                <div className={'mt-8 mx-6'}>
                    <TUInput label={'آدرس ایمیل'} onChange={handleSetEmail}/>
                </div>

                <div className={'flex justify-center mx-16 mt-8'}>
                    <button
                        onClick={handleNextStep}
                        className={'flex justify-center items-center rounded-full border-2 w-full py-6 bg-black'}>
                        <span className={'text-white text-base font-bold'}>ادامه</span>
                    </button>
                </div>
            </div>

        </>
    )
}