import * as Yup from "yup";

export interface IRequest {
    packageID: string,
    brokerListID: string,
    gatewayPrefix: string,
    discountCode?: string,
}

export const defaultValues: IRequest = {
    packageID: "",
    brokerListID: "",
    gatewayPrefix: "",
    discountCode: "",
}


export const YupSchema = Yup.object().shape({
    packageID: Yup.string(),
    brokerListID: Yup.string(),
    gatewayPrefix: Yup.string(),
    discountCode: Yup.string(),
})




export interface IResponseSinglePackage {
    id: string;
    languageID: string;
    name: string;
    investPrice: number;
    price: number;
    priceIRR: null | number;
    img: null | string;
    priority: number;
    get_items_count: number;
    get_items: IResponseSinglePackageItem[];
}

export interface IResponseSinglePackageItem {
    id: string;
    packageID: string;
    name: string;
    data: string;
    priority: number;
}