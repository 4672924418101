import {Route, Routes} from "react-router-dom";
import NotFound from "../TNTFramwork/Components/Pages/NotFound";
import LayoutSite from "./layout/LayoutSite";
import {HomePage} from "./Pages/HomePage";
import {router} from "../Constant/routes";
import {ContactUs} from "./Pages/ContactUs";
import {BlogList} from "./Pages/BlogList";
import {BlogDetails} from "./Pages/BlogDetails";
import {FAQ} from "./Pages/FAQ";
import {TermsAndConditions} from "./Pages/TermsAndConditions";
import {About} from "./Pages/About";

function RouterSite() {
    return (
        <LayoutSite>
            <Routes>
                <Route>

                    <Route index element={<HomePage/>}/>
                    <Route path={router.routerConfig.site.contactUs} element={<ContactUs/>}/>
                    <Route path={router.routerConfig.site.blogs} element={<BlogList/>}/>
                    <Route path={router.routerConfig.site.blogCategory} element={<BlogList/>}/>
                    <Route path={router.routerConfig.site.blogSearch} element={<BlogList />}/>
                    <Route path={router.routerConfig.site.blogDetail} element={<BlogDetails />}/>
                    <Route path={`${router.routerConfig.site.faq}`} element={<FAQ />}/>
                    <Route path={`${router.routerConfig.site.terms}`} element={<TermsAndConditions />}/>
                    <Route path={`${router.routerConfig.site.about}`} element={<About />}/>

                    <Route path={'*'} element={<NotFound/>}/>
                </Route>
            </Routes>
        </LayoutSite>
    )
}

export default RouterSite