import {toast} from "react-toastify";
import {setContextCustomerPanel} from "../Config/Context/ContextCustomerPanel";
import {baseURL} from "../../CustomerPanel/API/EndPoint/GlobalEndpoint";


export function showError(message: any) {
    if (Array.isArray(message)){
        message.map((value) => {
            toast.error(value)
        })
    }else {
        toast.error(message)
    }
}

export function showSuccess(message: any) {
    toast.success(message)
}

export function getCookie(name: string): string | undefined {
    const cookieString = document.cookie;
    if (cookieString) {
        const cookies = cookieString.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(`${name}=`)) {
                return cookie.substring(name.length + 1);
            }
        }
    }
    return undefined;
}

export function setCustomerPanelHeader(value: string){
    setContextCustomerPanel((prevState)=>{
        return {
            ...prevState,
            config:{
                ...prevState.config ,
                headerTitle: value,
            },

        }
    })
}

export function showAvatar(value: string | undefined){
    if (value){
        return `${baseURL}/storage/userPanel/userAvatar/${value}`
    }else {
        return '/image/defaultAvatar.jpg'
    }
}

export function showImage(value: string | undefined){
    return `${baseURL}/storage/${value}`
}


export const getQueryString = (queryName : string ) => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    return  urlParams.get(queryName)
}

export const showFlowStep = (value: string | undefined) => {
    if (value === 'test_step_1'){
        return 'چالش مرحله اول'
    }else if (value === 'test_step_2'){
        return 'چالش مرحله دوم'
    }else {
        return ''
    }
}