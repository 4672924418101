export const formatDate = (date: Date) => {
    const now = new Date();
    const diffInSeconds = Math.abs((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInMinutes < 1) return "لحظاتی پیش";
    else if (diffInMinutes < 60) return `${diffInMinutes} دقیقه قبل `;
    else if (diffInHours < 24) return `${diffInHours} ساعت قبل `;
    else return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}


export const dateFormatter = (dateTime: Date, locale = 'fa-IR') => {
    return Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit'
    }).format(new Date(dateTime))
}

export const dateFormatterFa = (dateTime: string) => {
    return Intl.DateTimeFormat('fa-IR', {
            // hour: '2-digit',
            // minute: '2-digit'
        }).format(new Date(dateTime))
        + ' - ' + Intl.DateTimeFormat('fa-IR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(dateTime))
}