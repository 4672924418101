import {ReactNode} from "react";

// eslint-disable-next-line
export const EvaluationSection = ({locale, scrollToPlanSection}: { locale: string, scrollToPlanSection: () => void }) => {

    return (
        <>
            <section className={`bg-background-black rounded-[80px] md:rounded-[0px]`}>
                <div className={`flex-col w-11/12 sm:w-full mx-auto sm:flex-row flex flex-wrap text-white lg:container py-24 items-end m-auto`}>
                    <div className={`w-full mb-12`}>
                        <h3 className={`text-4xl text-center`}>فرآیند شروع سرمایه گذاری</h3>
                    </div>
                    <div className={'w-11/12 mx-auto sm:w-2/3 flex flex-wrap flex-col sm:flex-row '}>
                        <div className={`w-full mb-8 text-center`}>
                            <span className={`circle-line border-white px-2 pb-2 text-sm `}>تجربه معاملات خود را به سطح بالاتری ببرید.</span>
                        </div>
                        <div className={'flex flex-wrap w-full'}>
                        <div className={`w-full sm:w-1/2 p-2 text-justify`}>
                            <EvaluationCard title={`پکیج مناسب`} shortText={`مرحله 1.`} >
                                <p>
                                    در این مرحله، یک طرح مناسب را برای خود انتخاب کنید و با کلیک بر روی آن، مشخصات مورد نظر خود را تنظیم و مراحل خرید را تکمیل کنید. قبل از این مرحله، اگر نیاز به آموزش یا ارتقا مهارت دارید، توصیه می‌کنیم به آکادمی "Core Trades" مراجعه کنید
                                </p>
                            </EvaluationCard>
                        </div>
                        <div className={`w-full sm:w-1/2 p-2 text-justify`}>
                            <EvaluationCard title={`شروع چالش`} shortText={`مرحله 2.`}>
                                <p>
                                    پس از تایید خرید و تکمیل ثبت نام، شما به طور خودکار به پنل معامله دسترسی پیدا می کنید و باید بر اساس قوانین هر پکیج و در زمان مشخص شده معامله کنید.
                                </p>
                            </EvaluationCard>
                        </div>
                        </div>
                    </div>

                    <div className={`w-11/12 mx-auto sm:w-1/3 p-2 text-justify`}>
                        <EvaluationCard title={`معامله گر سرپا`} topChild={
                            <div className={`w-full `}>
                                <button className={`blue-gradient text-white rounded-3xl w-full py-3 mb-8`} onClick={scrollToPlanSection}>زمان کسب درآمد</button>
                                <h5 className={`h5 text-blue-800 mb-8`}>مرحله نهایی</h5>
                            </div>
                        }>
                            <p>
                                پس از موفقیت در مرحله چالش‌، معامله گری را آغاز کنید. پس از بررسی حساب کاربری شما و شارژ کردن مقدار معادل پکیج خریداری شده در پنل شما، می‌توانید به فعالیت اصلی خود بپردازید. شما در حال تبدیل شدن به یک معامله گر یک شرکت تجاری هستید.
                            </p>
                        </EvaluationCard>
                    </div>
                </div>
            </section>
        </>
    )

}


const EvaluationCard = ({shortText, title, children, topChild}: { shortText?: string, title: string, children: ReactNode, topChild?: ReactNode }) => {
    return (
        <>
            <div className={`bg-white text-black flex-col rounded-2xl h-full p-4 py-8 flex`}>
                {topChild && <div>{topChild}</div>}
                {shortText && <span className={`text-gray-500`}>{shortText}</span>}
                <h4 className={`h4 mb-4 font-bold`}>{title}</h4>
                {
                    children
                }
            </div>
        </>
    )
}