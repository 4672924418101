import React from 'react';
import { Controller } from 'react-hook-form';
import { Textarea } from '@material-tailwind/react';

interface Props {
    label: string;
    name: string;
    control?: any;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    value?: string;
    onChange?: any;
}

export function TUTextArea({ control, name, label, className, onChange = () => console.log("onChange not provided")  }: Props) {
    return (
        <div>
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Textarea
                            {...field}
                            label={label}
                            className={`focus:ring-0 ${className}`}
                        />
                    )}
                />
            ) : (
                <Textarea
                    name={name}
                    label={label}
                    className={`focus:ring-0 ${className}`}
                    onChange={onChange}
                />
            )}
        </div>
    );
}
