import {useParams} from "react-router-dom";
import {FC, useEffect, useState} from "react";
import {SVGSearchIcon} from "../components/SVG/SVGDollar";
import {setGlobalContext} from "../../TNTFramwork/Config/Context/GlobalContext";
import {getAPI} from "../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {router} from "../../Constant/routes";

export const FAQ = () => {
    const {locale} = useParams()
    const [faqData, setFaqData] = useState<IFAQ[]>([])
    const load = async () => {
        await getAPI(`/faq/getTicketFAQ`, '', false).then((response) => {
            const responseData: IFAQ[] = response.data.data
            setFaqData(responseData)
        })
    }

    useEffect(() => {
        load()
    }, [])


    useEffect(() => {
        setGlobalContext((prev) => {
            const newState = {...prev};
            newState.site.menuMode = "black";
            return newState;
        })

        return () => {
            setGlobalContext((prev) => {
                const newState = {...prev};
                newState.site.menuMode = "white";
                return newState;
            })
        }
    }, [])


    return (
        <>
            <div className={`bg-black flex items-center flex-col justify-center py-12 pb-24`}>
                <h1 className={`font-bold text-center mb-4 text-[3rem] text-white`}>سوالات متداول</h1>
                <form action={`/${locale}${router.siteLink.blog.search}`} method={'get'} className={`input-group-icon flex flex-row items-center px-6 py-3 rounded-3xl bg-gray-black border border-1 border-gray-400`}>
                    <button className={'bg-transparent border-none outline-none'} type='submit'><SVGSearchIcon className={`fill-white`}/></button>
                    <input name={`query`} placeholder={`جستجوی مقاله`} className={`bg-transparent mr-6 outline-none w-full text-white `}/>
                </form>
            </div>
            <div className={`w-full mx-auto lg:container mt-7 bg-gradient-fade pb-12`}>
                <div className={`w-full md:w-8/12 px-2 mx-auto`}>
                    {/*<LinkNavigator key={`nav`} props={faqLinks}/>*/}
                </div>
                {faqData && faqData.length && faqData.map((x, index) => (
                    <FAQCard key={`faq_${index}`} {...x} />
                ))}
            </div>
        </>
    )
}


const FAQCard: FC<IFAQ> = (props) => {
    return (
        <>
            <div className={`bg-white rounded-3xl shadow rounded-3xl p-4 my-8`}>
                <h3 className={'font-bold text-[1.75rem] mt-4 mb-2'}>{props.question}</h3>
                <article className={'leading-8 text-gray-700'} dangerouslySetInnerHTML={{__html: props.answer}}></article>
            </div>
        </>
    )
}


export interface IFAQ {
    question: string;
    answer: string;
    priority: number;
}