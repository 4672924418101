import {getAPI, postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";

export interface IResponseGetAllBroker {
    id: string;
    name: string;
    prefix: string;
    platform: string;
    ApiID: null | string;
    priority: number;
    isActive: number;
}

export const ModuleServices = {
    async getListBroker() {
        const url = `/brokerList/getAll`;
        const response = await getAPI(url)
        const brokers = {
            mt4: [] as {value: string, label: string}[],
            mt5: [] as {value: string, label: string}[]
        };
        response.data.data.forEach((item: any) => {
            if (item.platform === 'mt4') {
                brokers.mt4.push({value: item.prefix , label: item.name});
            } else if (item.platform === 'mt5') {
                brokers.mt5.push({value: item.prefix , label: item.name});
            }
        });
        return brokers;
    },
    async verifyDiscount(data: string) {
        const url = `/verify-discount`;
        return await postAPI(url , {code: data})
    },
    async getPackageDetail(id: string) {
        const url = `/package/get/${id}`;
        const response = await getAPI(url)
        if (response.status === 200){
            return response
        }
    },
    async pay(data: string) {
        const url = `/paypoolRequest`;
        return await postAPI(url , data)
    },

};