import React from "react";


const InfoCard = ({textSmall, textBig, children} : {textSmall: string, textBig: string, children: React.ReactNode}) => {
    return (
        // <div className={'p-6 w-full sm:w-1/2 md:w-1/3 '}>
        <div className={'p-6 w-full sm:w-1/2 md:w-1/3 flex-grow flex-shrink-0'}>
            <div className={'h-[128px]  bg-white border-gray-200 shadow-lg transition-shadow rounded-xl'}>
                <div className={'flex items-center py-7 px-8'}>
                    {children}
                    <div className={'pr-4'}>
                        <span className={'text-xl text-black/[64] font-semibold block'}>{textBig}</span>
                        <span className={'text-sm text-[#8E8E93] font-normal block'}>{textSmall}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoCard