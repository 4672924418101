import React, { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { router } from "../../../../Constant/routes";
import { ModuleServices } from "../../../Services/Services";
import { SeparateCommaFormatter } from "../../../../TNTFramwork/Helper/NumberHelper";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

SwiperCore.use([Autoplay]);

import "swiper/swiper.min.css";
import { SVGArrow } from "../../SVG/SVGArrow";
import { FastYouFundLoaderComponent } from "../../../../TNTFramwork/Components/SVG/FastYouFundLoader";


export interface PlanModel {
  id: string,
  amount: number,
  investPrice: number,
  lang: string;
  buttonComponent?: FC<PlanModel>,
  items: PlanItem[]
}

export interface PlanItem {
  name: string,
  value: string,
  priority: number
}

export const PlanTable = ({ headerClass = "bg-white" }: {
  props?: PlanModel[],
  headerClass?: string
}) => {
  const [packageList, setPackageList] = useState<PlanModel[]>([]);
  const firstColumnRef = useRef<HTMLDivElement>(null);
  const [padding, setPadding] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Create a ResizeObserver instance
    const observer = new ResizeObserver((entries) => {
      setPadding(entries[0].contentRect.width);
    });

    // Start observing an element
    if (firstColumnRef.current) {
      observer.observe(firstColumnRef.current);
    }

    // Cleanup: stop observing on unmount
    return () => {
      if (firstColumnRef.current) {
        observer.unobserve(firstColumnRef.current);
      }
    };
  }, []);

  const load = async () => {
    setPackageList([]);
    await ModuleServices.getPackageList().then((response) => {
      const data = response.data.data;
      // eslint-disable-next-line
      data.map((item: any, index: number) => {
        setPackageList(prev => [...prev, {
          id: item.id,
          amount: item.name,
          lang: "fa",
          investPrice: item.investPrice,
          // eslint-disable-next-line
          items: item.get_items.map((item: any) => {
            return {
              name: item.name,
              value: item.data,
              priority: item.priority
            };
          })
        }]);
      });
      setLoading(false);
    });
  };
  useEffect(() => {
    load();
  }, []);
  return (
    <>
      {loading ? (
        <div className="flex justify-center my-[10%]">
          <FastYouFundLoaderComponent />
        </div>
      ) : (
        <>
          <div className={`hidden sm:block [direction:ltr] relative`}>
            <div className={`overflow-x-auto overflow-y-hidden plan`} style={{ paddingRight: `${padding}px` }}>
              <div
                className="plan-table w-fit pb-4 justify-center [&>*:nth-child(even)>*:not(:first-child)]:bg-indigo-50 flex [direction:rtl] shadow-xl rounded-2xl">
                <div
                  className={`plan-col first-card text-center justify-between h-[calc(100%-1rem)] flex-wrap flex flex-col flex-grow-[2] right-0 top-0 absolute ${headerClass}`}
                  ref={firstColumnRef}>
                  <h3 key={`item_0`} className={`h4 py-6 min-w-[180px] font-bold ${headerClass}`}>مقدار بودجه</h3>
                  {packageList.length && packageList[0].items.sort((a, b) => a.name.localeCompare(b.name)).map((planItemName, i) => (
                    <h5 key={`item_${i}`}
                        className={`h6 shadow-[0_11px_32px_-15px_#c1add547] py-6 font-bold min-w-[100px] ${headerClass}`}>{planItemName.name}</h5>
                  ))}
                  {/*<h5 className={`h6 v py-4 font-bold whitespace-normal min-w-[180px] ${headerClass}`}>برنامه مورد نظر*/}
                  {/*  خود را <br />همین حالا دریافت کنید</h5>*/}
                </div>
                {packageList.length && packageList.sort((a, b) => b.investPrice - a.investPrice).map((plan, planIndex) => (
                  <div className={`flex plan-col flex-col flex-grow h-full text-center`} key={planIndex}>
                    <h3 key={`first_item_h3`}
                        className={`h4 py-6 min-w-[100px] font-bold`}>{SeparateCommaFormatter(plan.investPrice)}$</h3>
                    {plan.items.sort((a, b) => a.name.localeCompare(b.name)).map((planItem, index) => (
                      <h5 key={`plan_${planIndex}_${index}`}
                          className={`h6 w-fit min-w-full py-6 px-6 shadow-[0_11px_32px_-15px_#c1add547] font-bold`}>{planItem.value}</h5>
                    ))}
                    <div className={"py-6 "}>
                      <NavLink to={router.customerPanelLink.package.checkout(plan.id)} key={`btn_buy_${planIndex}`}
                               className={`btn bg-black text-white shadow-[0_11px_32px_-15px_#c1add547] text-sm py-6 px-6 font-bold rounded-2xl m-auto`}>تامین
                        مالی شود</NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`block sm:hidden`}>
            <div
              className="swiper-navigation relative z-10 items-center justify-between flex h-full top-0 left-0 w-full -mb-28">
              <div className="swiper-button-next"><SVGArrow fill={"black"} arrowFill={"white"} /></div>
              <div className="swiper-button-prev"><SVGArrow className={`rotate-180`} fill={"black"}
                                                            arrowFill={"white"} /></div>
            </div>
            {packageList && packageList.length && (
              <div className={`relative py-4 md:px-24`}>
                <Swiper className={`items-stretch`}
                        modules={[Navigation]}
                        spaceBetween={25}
                        slidesPerView={planPerView()}
                        speed={1500}
                        loop={true}
                        touchRatio={1.5}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev"
                        }}
                        effect={"flip"}
                        autoplay
                >
                  {packageList.map((plan, planIndex) => (
                    <SwiperSlide key={`swiper_${planIndex}`} className={`h-full`}>
                      <div key={planIndex} className={`flex flex-col flex-grow text-center `}>
                        <h3 key={`first_item_h3`}
                            className={`h4 py-6 font-bold`}>{SeparateCommaFormatter(plan.investPrice)}$</h3>
                      </div>
                      <div
                        className="plan-table w-full justify-center [&>*:nth-child(even)]:bg-indigo-50 flex [direction:ltr] shadow-xl rounded-2xl overflow-hidden">
                        <div className={`first-card text-center flex flex-col flex-grow-[2]`}>
                          {plan.items.sort((a, b) => b.priority - a.priority).map((planItemName, i) => (
                            i > 0 ? (
                              <h5 key={`item_${i}`}
                                  className={`h6 shadow-[0_11px_32px_-15px_#c1add547] py-6 font-bold`}>{planItemName.name}</h5>
                            ) : (
                              <span key={`item_${i}`}></span>
                            )
                          ))}
                          <h5 className={`h6 v py-4 font-bold whitespace-normal`}>دریافت برنامه</h5>
                        </div>
                        <div className={`flex flex-col flex-grow text-center`} key={planIndex}>
                          {plan.items.sort((a, b) => a.priority - b.priority).map((planItem, index) => (
                            index > 0 ? (
                              <h5 key={`plan_${planIndex}_${index}`}
                                  className={`h6 py-6 px-6 shadow-[0_11px_32px_-15px_#c1add547] font-bold`}>{planItem.value}</h5>
                            ) : (
                              <span key={`item_${index}`}></span>
                            )
                          ))}
                          <NavLink to={router.customerPanelLink.package.checkout(plan.id)} key={`btn_buy_${planIndex}`}
                                   className={`btn bg-black text-white shadow-[0_11px_32px_-15px_#c1add547] text-sm py-6 px-6 font-bold rounded-2xl m-auto`}>شروع
                            کن</NavLink>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );

};


export const planPerView = () => {
  const width = window.innerWidth;
  if (width < 560) {
    return 1;
  } else if (width < 768) {
    return 2;
  } else if (width < 1600) {
    return 3;
  } else {
    return 4;
  }

};

