import {NavLink, useParams} from "react-router-dom";
import {Fragment} from "react";
import {Link} from "../../Config/interfaces/BlogInterface";
import {router} from "../../../Constant/routes";


export const LinkNavigator = ({items}:{items: Link[]}) => {
    const {locale} = useParams()
    const activeClass = `transition w-fit whitespace-nowrap font-bold text-[13px] px-6 py-2 rounded-3xl text-sm bg-blue-700 text-white mx-3`
    const inactiveClass = `transition  w-fit whitespace-nowrap font-bold text-[13px]  rounded-3xl text-sm mx-3`
    const pageUrl = decodeURIComponent((new URL(window.location.href)).pathname);
    const defaultUrl = `/${locale}${router.siteLink.blog.list}`

    return (
        <>
            <nav className={`overflow-x-auto w-full  space-x-8 flex-nowrap flex flex-row items-center h-full md:px-4 py-2 rounded-3xl bg-gray-200`}>
                <NavLink key={`99999`} to={defaultUrl} className={defaultUrl == pageUrl ? activeClass : inactiveClass}>{'همه'}</NavLink>
                {items && items.length > 0 && items.map((x, index: number )=> {
                    return  <Fragment key={index}>
                        {
                            x.url
                              ? <NavLink key={`cat_${index}`} to={x.url} className={x.url == pageUrl ? activeClass : inactiveClass}>{x.label}</NavLink>
                              : null
                        }
                    </Fragment>
                })}
            </nav>
        </>
    )
}