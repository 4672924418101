import {useState} from "react";
import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import SerialInput from "../../../../TNTFramwork/Components/Fields/SerialInput";

export function LoginStepTwo(props: any) {
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState('')

    const handleVerify = async (event: any) => {
        setLoading(true)
        event.preventDefault()
        try {
            const response = await postAPI('/loginStepVerifyOTP', {email: props.email , otp}, setLoading);
            if (response.status == 200) {
                props.goToNextStep()
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleSetOtp = (e: any) => {
        setOtp(e)
    }

    return (
        <>
            <div className={'w-full'}>
                <div className={'text-white md:text-gray-600 flex '}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="currentColor" className="w-8 h-8 md:hidden">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                    <div className={'flex items-center'}>
                        <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'}
                             height={'39px'}/>
                        <h1 className={'px-2 text-black font-extrabold uppercase'}>Core Trades</h1>
                    </div>
                </div>

                <div className={'mt-10 text-[#292D34]'}>
                    <span className={'text-xl font-bold leading-5'}>فعالسازی اکانت</span>
                </div>
                <div className={'mt-3 text-[#000000B2]'}>
                    <span className={'text-sm font-normal leading-5'}>{`لطفا کد فعالسازی ارسال شده به ایمیل ${props.email} را وارد کنید`}</span>
                </div>
                <div className={'mt-8 mx-6'}>
                    <SerialInput onChange={handleSetOtp} dir={'ltr'} className={'justify-end'} inputSize={4}/>
                </div>

                <div className={'flex justify-start w-3/4 mt-8'}>
                    <button
                        onClick={handleVerify}
                        className={'flex justify-center items-center rounded-full border-2 w-full py-6 bg-black'}>
                        <span className={'text-white text-base font-bold'}>فعالسازی</span>
                    </button>
                </div>
                <div className={'pt-2'}>
                    <div className={'block py-2'}>
                        <p className={'cursor-pointer text-sm text-blue-600 text-lg font-normal'}>کد فعالسازی دریافت نکرده اید؟</p>
                    </div>
                    <button className={''} onClick={props.goToPreviousStep}><span className={'text-sm underline'}>تغییر ایمیل</span></button>
                </div>
            </div>
        </>
    )
}