
export const SVGArrowRight = ({className}:{className?:string})=>{
    return (
        <>
        <svg width="24" className={className??''}  height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.43 5.92969L20.5 11.9997L14.43 18.0697" stroke="#0067FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5 12H20.33" stroke="#0067FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </>
    )
}
export const SVGArrow = ({className,fill = 'white' , arrowFill='#111827'}:{className?:string,fill?:string,arrowFill?:string})=>{
    return (
        <svg width="120" height="120" className={className??''} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_bd_449_12055)">
                <rect x="36" y="32" width="48" height="48" rx="24" fill={fill} shapeRendering="crispEdges"/>
                <g filter="url(#filter1_bd_449_12055)">
                    <circle cx="60" cy="56" r="24" fill={fill}/>
                    <circle cx="60" cy="56" r="23.5" stroke={arrowFill} strokeOpacity="0.1"/>
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M58.2929 62.7071C57.9024 62.3166 57.9024 61.6834 58.2929 61.2929L63.5858 56L58.2929 50.7071C57.9024 50.3166 57.9024 49.6834 58.2929 49.2929C58.6834 48.9024 59.3166 48.9024 59.7071 49.2929L65.7071 55.2929C66.0976 55.6834 66.0976 56.3166 65.7071 56.7071L59.7071 62.7071C59.3166 63.0976 58.6834 63.0976 58.2929 62.7071Z" fill={arrowFill}/>
                <rect x="36.5" y="32.5" width="47" height="47" rx="23.5" stroke={arrowFill} strokeOpacity="0.1" shapeRendering="crispEdges"/>
            </g>
            <defs>
                <filter id="filter0_bd_449_12055" x="-16" y="-20" width="152" height="152" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="26"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_449_12055"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="18"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_449_12055" result="effect2_dropShadow_449_12055"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_449_12055" result="shape"/>
                </filter>
                <filter id="filter1_bd_449_12055" x="-16" y="-20" width="152" height="152" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="26"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_449_12055"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="18"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_449_12055" result="effect2_dropShadow_449_12055"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_449_12055" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}