import * as Yup from "yup";

export interface FormFields {
    password: string;
    repeatPassword: string;
}
export const FormFieldsValidationSchema = Yup.object().shape({
    password: Yup.string()
        .required('فیلد پسورد اجباری است')
        .min(3, 'پسورد کوتاه است ، حداقل کاراکتر های پسورد 3 عدد است')
    ,
        // .matches(/[a-zA-Z]/, 'پسورد می تواند فقط شامل کاراکتر های انگلیسی باشد'),
    repeatPassword: Yup.string()
        .required('فیلد تکرار پسورد اجباری است')
        .oneOf([Yup.ref('password')], 'فیلد پسورد با فیلد تکرار پسورد یکسان نیست')
});


export interface FormFieldsUserInformation {
    firstName: string | undefined;
    lastName: string | undefined;
    phone: string | undefined;
}
export const FormFieldsDefaultValueUserInformation : FormFieldsUserInformation=  {
    firstName: '',
    lastName: '',
    phone: '',
}
export const FormFieldsUserInformationValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('فیلد نام اجباری است')
    ,
    lastName: Yup.string()
        .required('فیلد نام خانوادگی اجباری است')
    ,
    phone: Yup.string()
        .required('فیلد شماره همراه اجباری است')
        .matches(/^09\d{9}$/, 'شماره همراه باید با 09 شروع شود و حتما 11 کاراکتر باشد')
    ,
});