import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// eslint-disable-next-line
export const BenefitSection = ({ locale }: { locale: string }) => {
  const perView = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      return true;
    } else {
      return false;
    }
  };

  const isMobile = perView();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <>
      <section
        className={`px-4 md:px-12 lg:px-0 flex flex-wrap py-16 sm:py-[7rem]  justify-evenly items-center bg-circle-single-light`}>
        <div className={`w-full lg:w-4/12 pl-4 text-justify`}>
          <h1 className={`leading-[60px] text-[2.5rem] sm:text-[3.5rem] mb-3 sm:mb-8 font-bold`}> جادوی
            تریدینگ <br /> Core Trades</h1>
          <p className={`text-md sm:text-xl`}>
            Core Trades، یک پلتفرم پراپ تریدینگ هوشمند و سریع است که با سرعت بالا، ابزارهای پیشرفته و پنل آنالیز
            حرفه‌ای، به شما امکان می‌دهد تا در بازارهای مالی رقابتی با هوش و استراتژی بهتری عمل کنید و به سودآوری بیشتری
            دست یابید.

          </p>
          <p className={`text-md sm:text-xl`}>
            بیابان پراپ تریدینگ را با Core Trades به یک باغ پراز رشد و موفقیت تبدیل کنید.
          </p>
        </div>
        <div className={`bg-circle-fade-single-blue before:w-[60vw] before:h-[60vw] before:left-2/3 mt-8 lg:mt-0 before:top-0
                    sm:before:hidden w-11/12 md:w-8/12 lg:w-5/12 flex gap-1 md:gap-4 lg:gap-0 flex-wrap relative justify-evenly lg:items-start pt-4 lg:pt-0`}>
          {isMobile ? (
            <Slider className={`w-full [&>div]:h-full [&>div>div]:h-full [&>div>div>div>div]:h-full`} {...settings}>
              <div className={"px-2"}>
                <BenefitCardIMG
                  title={`پنل کاربری پیشرفته و کارآمد`}
                  description={`دسترسی سریع و ساده، گزارش‌گیری جامع، سفارشات و اجراهای سریع، امنیت بالا، حفاظت اطلاعات، تحلیل بازار، ابزارهای پیشرفته.`}
                  className={`bg-white `}
                >
                  <LazyLoadImage
                    src={`/image/icon/star.png`}
                    alt={"fastyoufund"}
                    effect="blur"
                    width={84}
                    className={`object-contain top-0 left-0`}
                  />
                </BenefitCardIMG>
              </div>
              <div className={"px-2"}>
                <BenefitCardIMG
                  title={`امنیت و حفاظت اطلاعات`}
                  description={`امنیت و حفاظت اطلاعات بالا با رمزنگاری قوی، تشخیص تقلب و مکانیزم‌های امنیتی حفظ می‌شود تا اطلاعات شخصی و مالی کاربران در پنل کاربری "Core Trades" محافظت شود.`}
                  className={`bg-white lg:mt-8`}
                >
                  <LazyLoadImage
                    src={`/image/icon/open-lock 1.png`}
                    alt={"fastyoufund"}
                    effect="blur"
                    width={84}
                    className={`object-contain top-0 left-0`}
                  />
                </BenefitCardIMG>
              </div>
              <div className={"px-2"}>
                <BenefitCardIMG
                  title={`پنل آنالیز حرفه‌ای`}
                  description={`پنل آنالیز حرفه‌ای و Real-Time در "Core Trades"، امکان تحلیل بازار، دسترسی به اطلاعات، نمودارهای زنده و ابزارهای پیشرفته را بلادرنگ فراهم می‌کند.`}
                  className={`bg-background-black text-white`}
                >
                  <LazyLoadImage
                    src={`/image/icon/analyze.png`}
                    alt={"fastyoufund"}
                    effect="blur"
                    width={84}
                    className={`object-contain top-0 left-0`}
                  />
                </BenefitCardIMG>
              </div>
              <div className={"px-2"}>
                <BenefitCardIMG
                  title={`تعرفه مناسب اکانت`}
                  description={`تعرفه‌های مناسب و انعطاف‌پذیر برای اکانت‌های "Core Trades" با قابلیت‌های کامل و دسترسی به ابزارهای پیشرفته ارائه می‌شود.`}
                  className={`bg-white  lg:mt-8`}
                >
                  <LazyLoadImage
                    src={`/image/icon/hand-snail.png`}
                    alt={"fastyoufund"}
                    effect="blur"
                    width={84}
                    className={`object-contain top-0 left-0`}
                  />
                </BenefitCardIMG>
              </div>
            </Slider>
          ) : (
            <>
              <BenefitCardIMG
                title={`پنل کاربری پیشرفته و کارآمد`}
                description={`دسترسی سریع و ساده، گزارش‌گیری جامع، سفارشات و اجراهای سریع، امنیت بالا، حفاظت اطلاعات، تحلیل بازار، ابزارهای پیشرفته.`}
                className={`bg-white `}
              >
                <LazyLoadImage
                  src={`/image/icon/star.png`}
                  alt={"fastyoufund"}
                  effect="blur"
                  width={84}
                  className={`object-contain top-0 left-0`}
                />
              </BenefitCardIMG>
              <BenefitCardIMG
                title={`امنیت و حفاظت اطلاعات`}
                description={`امنیت و حفاظت اطلاعات بالا با رمزنگاری قوی، تشخیص تقلب و مکانیزم‌های امنیتی حفظ می‌شود تا اطلاعات شخصی و مالی کاربران در پنل کاربری "Core Trades" محافظت شود.`}
                className={`bg-white lg:mt-8`}
              >
                <LazyLoadImage
                  src={`/image/icon/open-lock 1.png`}
                  alt={"fastyoufund"}
                  effect="blur"
                  width={84}
                  className={`object-contain top-0 left-0`}
                />
              </BenefitCardIMG>

              <BenefitCardIMG
                title={`پنل آنالیز حرفه‌ای`}
                description={`پنل آنالیز حرفه‌ای و Real-Time در "Core Trades"، امکان تحلیل بازار، دسترسی به اطلاعات، نمودارهای زنده و ابزارهای پیشرفته را بلادرنگ فراهم می‌کند.`}
                className={`bg-background-black text-white`}
              >
                <LazyLoadImage
                  src={`/image/icon/analyze.png`}
                  alt={"fastyoufund"}
                  effect="blur"
                  width={84}
                  className={`object-contain top-0 left-0`}
                />
              </BenefitCardIMG>
              <BenefitCardIMG
                title={`تعرفه مناسب اکانت`}
                description={`تعرفه‌های مناسب و انعطاف‌پذیر برای اکانت‌های "Core Trades" با قابلیت‌های کامل و دسترسی به ابزارهای پیشرفته ارائه می‌شود.`}
                className={`bg-white  lg:mt-8`}
              >
                <LazyLoadImage
                  src={`/image/icon/hand-snail.png`}
                  alt={"fastyoufund"}
                  effect="blur"
                  width={84}
                  className={`object-contain top-0 left-0`}
                />
              </BenefitCardIMG>
            </>
          )}
          <div className="hidden absolute z-[-1] md:block md:w-[800px] md:h-[941px]">
            <Helmet>
              <link rel="preload" as="image" href="/image/site/home/blurCircle.webp" />
            </Helmet>
            <LazyLoadImage
              src={`/image/site/home/blurCircle.webp`}
              alt={"fastyoufund"}
              effect="blur"
              className={`object-contain top-0 left-0`}
            />
          </div>
        </div>
      </section>
    </>

  );
};

const BenefitCardIMG = ({
                          title,
                          description,
                          className,
                          children
                        }: { title: string, description: string, className: string, children: any }) => {
  return (
    <>
      <div className={`justify-center items-center flex-col  text-center  shadow border
            flex-wrap inline-flex lg:flex
            w-full xs:w-[calc(50%-0.5rem)] lg:w-[calc(50%-0.75rem)]
            rounded-3xl  sm:rounded-2xl 
            py-12  xs:py-4 sm:!py-12  mb-2 md:!py-5 px-5 
            lg:m-1 h-full md:h-[unset]
              ${className}`}>
        <h3 className={`mb-2 font-bold`}>{title}</h3>
        {children}
        <p className={`text-[13px] mt-2 font-medium`}>{description}</p>
      </div>
    </>
  );
};

