import React, {useEffect, useState} from "react";
import {TUSelect} from "../../../../TNTFramwork/Components/Shared/Select/TailwindMaterial/TUSelect";
import TableReactTable from "../../../../TNTFramwork/Components/Shared/TableReactTable";
import {PaginationSimple} from "../../../../TNTFramwork/Components/Shared/Pagination/PaginationSimple";
import {IPaginate} from "../../../../TNTFramwork/Components/Shared/Pagination/Interfaces";
import {ModuleServices} from "../Services/Services";
import { Link, useNavigate } from "react-router-dom";
import {router} from "../../../../Constant/routes";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";


function TicketList() {
    const navigate = useNavigate()
    const [row, setRow] = useState([])
    const [paginateData, setPaginateData] = useState<IPaginate>()
    const [TableLoading, setLoading] = useState(true)
    const load = async () => {
        await ModuleServices.getList().then((response) => {
            setRow(response.data.data)
            setLoading(false)
        })
    }
    const handleConversation = (id: string) => {
        navigate(router.customerPanelLink.tickets.conversation(id))
    }

    useEffect(() => {
        load()
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "ردیف",
                accessor: "rowNumber",
                disableSortBy: true
            },
            {
                Header: "موضوع",
                accessor: "title",
            },
            {
                Header: 'دپارتمان',
                accessor: "category",
                disableSortBy: true
            },
            {
                Header: 'وضعیت',
                accessor: "status",
                disableSortBy: true
            },
            {
                Header: "شماره درخواست",
                accessor: "requestNumber",
            },
            {
                Header: "آخرین بروزرسانی",
                accessor: "last_update",
            },
            {
                Header: "عملیات",
                accessor: 'operation',
                Cell: ({cell}: { cell: any }) => (
                    <div className={'flex  justify-end'}>
                        <div className="inline-flex">
                            <button onClick={() => {
                                handleConversation(cell.row.original.id)
                            }} className={'flex items-center '}>
                                <span>مشاهده جزئیات</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.05 19.9201L8.53005 13.4001C7.76005 12.6301 7.76005 11.3701 8.53005 10.6001L15.05 4.08008"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                )
            },
        ],
        []
    );

    return (
        <>
            <div className={'userPanelContainer overflow-x-hidden'}>
                <div className={'userPanelContainerHeader'}>
                    <span className={'font-bold text-lg'}>تیکت ها</span>
                    <Link to={router.customerPanelLink.tickets.new}
                          className={'rounded-full border-2 px-4 py-1 border-black font-semibold'}>
                        ایجاد تیکت جدید
                    </Link>
                </div>
                <div className={'py-2 px-6 border-t-2 border-b-2'}>
                    <div className={'flex flex-wrap items-center '}>
                        <div className={'flex flex-row flex-wrap items-center w-full sm:w-1/2 md:w-1/3 flex-grow flex-shrink-0'}>
                            <div className={'w-full  md:w-1/3 lg:w-3/5 px-3 my-2'}>
                                <TUInput  containerProps={'!min-w-[unset]'} label={'جستجو'} className={''}/>
                            </div>
                            <div className={'w-full xs:w-1/2 md:!w-1/3 lg:!w-1/5 px-3 my-2'}>
                                <TUSelect label={'وضعیت'}
                                          selectFirstItem={true}
                                          containerProps={'!min-w-[unset]'}
                                          items={[
                                              {value: 'all', label: 'همه'},
                                              {value: 'pending', label: 'در حال پیگیری'},
                                              {value: 'in_process', label: 'در حال بررسی'},
                                              {value: 'answer', label: 'جواب داده شده'},
                                              {value: 'closed', label: 'بسته شده'},
                                          ]}
                                />
                            </div>
                            <div className={'w-full xs:w-1/2 md:!w-1/3 lg:!w-1/5 px-3 my-2'}>

                                <TUSelect label={'دپارتمان'}
                                          selectFirstItem={true}
                                          containerProps={'!min-w-[unset]'}
                                          items={[
                                              {value: 'all', label: 'همه'},
                                              {value: 'sales', label: 'دپارتمان فروش'},
                                              {value: 'technical', label: 'دپارتمان فنی'},
                                              {value: 'accounting', label: 'دپارتمان مالی'},
                                              {value: 'management', label: 'دپارتمان مدیریت'},
                                          ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <TableReactTable columns={columns} data={row} TableLoading={TableLoading}
                                 emptyDataImageProp={'/image/ticketNotFound.png'}
                                 emptyDataTextProp={'تیکتی برای نمایش وجود ندارد'}
                    // tableTitle={'لیست سرویس های من'}
                >
                    {(paginateData) && (
                        <PaginationSimple paginationData={paginateData} setRow={setRow}
                                          setPaginateData={setPaginateData}/>
                    )}
                </TableReactTable>
            </div>
        </>
    )
}

export default TicketList