import {FC} from "react";
import {formatDate} from "../../../TNTFramwork/Helper/DateHelper";
import {NavLink} from "react-router-dom";
import {router} from "../../../Constant/routes";
import {DataItem} from "../../Config/interfaces/BlogInterface";
import {showImage} from "../../../TNTFramwork/Helper/helper";

export const BlogCard :FC<DataItem> = (props) => {
    const locale = `fa`;
    return (
        <>
            <NavLink to={`/${locale}${router.siteLink.blog.details(props.slug)}`} className={`block mx-4 mb-14`}>
                <div className={`image-container mx-8 overflow-hidden max-h-[20vh] relative z-2 rounded-3xl`}>
                    <img src={showImage(props.thumb)} className={`max-w-full max-h-full`} />
                </div>
                <div className={`pt-20 shadow pb-7 px-4 mt-[-4rem] rounded-3xl  text-container bg-white border border-solid border-[#0000000d]`}>
                    <h3 className={`font-bold h6 mb-4`}>{props.name}</h3>
                    <span className={`text-gray-400 text-sm`}>{formatDate(new Date(props.publishDate))}</span>
                </div>
            </NavLink>
        </>
    )
}

