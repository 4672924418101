import {HTMLAttributes} from "react";


export const SVGMenu = (props?:HTMLAttributes<any>)=>{
    return (
        <>
            <svg {...props} width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.9615 2H1.03846C0.470769 2 0 1.54667 0 1C0 0.453333 0.470769 0 1.03846 0H25.9615C26.5292 0 27 0.453333 27 1C27 1.54667 26.5292 2 25.9615 2Z" fill="#292D32"/>
                <path d="M25.9615 11H1.03846C0.470769 11 0 10.5467 0 10C0 9.45333 0.470769 9 1.03846 9H25.9615C26.5292 9 27 9.45333 27 10C27 10.5467 26.5292 11 25.9615 11Z" fill="#292D32"/>
                <path d="M25.9615 20H1.03846C0.470769 20 0 19.5467 0 19C0 18.4533 0.470769 18 1.03846 18H25.9615C26.5292 18 27 18.4533 27 19C27 19.5467 26.5292 20 25.9615 20Z" fill="#292D32"/>
            </svg>

        </>
    )
}