import './App.css'
import { Suspense } from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import CustomerAuthRoutes from "./TNTFramwork/Config/Middleware/CustomerAuthRoutes";

import RouterCustomerPanel from "./CustomerPanel/RouterCustomerPanel";
import NotFound from "./TNTFramwork/Components/Pages/NotFound";
import RouterSite from "./Site/RouterSite";
import {router} from "./Constant/routes";
import {BaseStepper} from "./CustomerPanel/Modules/LoginStepper/Page/BaseStepper";
import axios from "axios";
import {sanctumCookieURL} from "./CustomerPanel/API/EndPoint/GlobalEndpoint";
import {CustomerAuthSiteRoutes} from "./TNTFramwork/Config/Middleware/CustomerAuthSiteRoutes";
import { FastYouFundLoader } from "./TNTFramwork/Components/SVG/FastYouFundLoader";
import { ForgotPassword } from "./CustomerPanel/Modules/LoginStepper/Page/ForgotPassword";
import { TenetInput } from "./TenetInput";



function App() {
    axios.get(sanctumCookieURL , {withCredentials: true})
    return (
      <Suspense fallback={<FastYouFundLoader/>}>
        <Routes>
            <Route path={'/'} element={<Navigate to={'/fa'} />} />
            <Route element={<CustomerAuthRoutes/>}>
                <Route path={router.routerConfig.customerPanel.base} element={<RouterCustomerPanel/>}/>
            </Route>
            <Route path={router.routerConfig.customerPanel.login} element={<BaseStepper />} />
            <Route path={router.routerConfig.customerPanel.forgotPassword} element={<ForgotPassword />} />

            <Route element={<CustomerAuthSiteRoutes/>}>
                <Route path={router.routerConfig.site.base} element={<RouterSite/>}/>
            </Route>
            <Route path={'test'} element={<TenetInput/>}/>
            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
      </Suspense>
    )
}

export default App
