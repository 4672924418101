import {Route, Routes} from 'react-router-dom';
import List from "./Pages/List";
import NotFound from "../../../TNTFramwork/Components/Pages/NotFound";

export function RouterAccounts(){
    return (
        <Routes>
            <Route index element={<List />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
