import {Button, PageButton} from "./Button";
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/20/solid";
import React from "react";
import {getAPIFullURL} from "../../../Config/Axios/AxiosCustomerConfig";
import {IPaginate} from "./Interfaces";
import {createSelectPageList} from "./Utils";

export const PaginationSimple = ({
                                     paginationData,
                                     setRow,
                                     setPaginateData,
                                     pageCounter
                                 }: { paginationData?: IPaginate, setRow: any, setPaginateData: any, pageCounter?: boolean }) => {
  const canPreviousPage = paginationData && paginationData.prev_page_url !== null;
  const canNextPage = paginationData && paginationData.next_page_url !== null;
  const canFirstPage = paginationData && paginationData.current_page !== 1;
  const canLastPage = paginationData && (paginationData.current_page !== paginationData.last_page);

  const selectPages = paginationData ? createSelectPageList(paginationData) : [];
    const handleChangeSelectPage = async (event: any) => {
        await paginateFetch(event.target.value , setRow , setPaginateData)
    }
    return (
        <>
          {(paginationData?.next_page_url !== null && paginationData?.next_page_url !== '' || paginationData?.current_page > 1) && (
                <>
                    <div className="py-3 flex items-center justify-center  bg-gray-50">
                        <div className="flex-1 flex justify-center sm:hidden">
                            <Button
                                onClick={() => paginateFetch(paginationData!.next_page_url, setRow, setPaginateData)}
                                disabled={!canNextPage}
                                className={`${(canNextPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'} `}>بعدی</Button>
                            <Button
                                onClick={() => paginateFetch(paginationData!.prev_page_url, setRow, setPaginateData)}
                                disabled={!canPreviousPage}
                                className={`${(canPreviousPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'} `}>قبلی</Button>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center justify-center">
                            <div>
                                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                     aria-label="Pagination">
                                    <PageButton
                                        onClick={() => paginateFetch(paginationData!.last_page_url, setRow, setPaginateData)}
                                        disabled={!canLastPage}
                                        className={`rounded-r-md ${(canLastPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'}`}
                                    >
                                        <span className="sr-only">آخرین</span>
                                        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true"/>
                                    </PageButton>
                                    <PageButton
                                        onClick={() => paginateFetch(paginationData!.next_page_url, setRow, setPaginateData)}
                                        disabled={!canNextPage}
                                        className={`${(canNextPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'} `}
                                    >
                                        <span className={'sr-only'}>بعدی</span>
                                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" title={'بعدی'}/>
                                    </PageButton>
                                    <select className={'border-0'} onChange={handleChangeSelectPage}
                                            defaultValue={selectPages.find(item => item.active).url}
                                    >
                                        {selectPages.map((item , index) => (
                                            <option key={index} value={item.url} disabled={(item.active)}>{'صفحه'} {item.label}</option>
                                        ))}
                                    </select>
                                    <PageButton
                                        onClick={() => paginateFetch(paginationData!.prev_page_url, setRow, setPaginateData)}
                                        disabled={!canPreviousPage}
                                        className={`${(canPreviousPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'} `}
                                    >
                                        <span className={'sr-only'}>قبلی</span>
                                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" title={'قبلی'}/>
                                    </PageButton>
                                    <PageButton
                                        onClick={() => paginateFetch(paginationData!.first_page_url, setRow, setPaginateData)}
                                        disabled={!canFirstPage}
                                        className={`rounded-l-md ${(canFirstPage) ? 'bg-white hover:bg-gray-50' : 'bg-gray-300 cursor-default'} `}
                                    >
                                        <span className="sr-only">اولین</span>
                                        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" title={'اولین'}/>
                                    </PageButton>
                                </nav>
                            </div>
                        </div>

                    </div>
                    {pageCounter && (
                        <div>
                            <span>{`صفحه ${paginationData!.current_page} از ${paginationData!.last_page}`}</span>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

const paginateFetch = async (url: string | null, setRow: any, setPaginateData: any) => {
  setRow([])
  if (url) { // only make the request if url is not null
    await getAPIFullURL(url).then((response) => {
      setRow(response.data.data.data)
      setPaginateData(response.data.data)
    });
  }
}

