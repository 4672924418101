import {Route, Routes} from 'react-router-dom';
import NotFound from "../../../../TNTFramwork/Components/Pages/NotFound";
import {DownloadList} from "../Pages/DownloadList";

export function RouterDownload(){
    return (
        <Routes>
            <Route index element={<DownloadList />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
