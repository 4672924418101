import {ReactNode} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";


export const PartneringBenefitSection = () => {

    return (
        <>
            <section className={`partnering-benefit flex flex-row flex-wrap justify-center px-2 py-20 bg-black text-white rtl bg-circle-single-light-blue`}>
                <div className={`w-full sm:w-11/12 lg:w-10/12 text-center`}>
                    <h2 className={`font-bold text-[3rem] mb-7`}>سود شما == پیروزی ما</h2>
                    <p className={`mb-2 mx-auto max-w-full md:max-w-[80%]`}>
                        وقتی چالش مرحله‌ای دوم را پشت سر می‌گذارید، "Core Trades" سرمایه واقعی را برای تجارت در بازارهای زنده فراهم می‌کند.
                    </p>
                    <p className={'mb-16 mx-auto max-w-full md:max-w-[80%]'}>هدف ما یافتن معامله گران واقعی و بازدهی بالا در بازار های مالی است</p>
                    <div className={`w-full flex flex-wrap flex-row justify-center`}>
                        <PartneringBenefitCard title={`پشتیبانی سریع با چت آنلاین و تیکت`}>
                            <LazyLoadImage
                              src={`/image/icon/speech-bubble 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                        <PartneringBenefitCard title={`تقسیم سود 10% شرکت و 90% تریدر`}>
                            <LazyLoadImage
                              src={`/image/icon/safe 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                        <PartneringBenefitCard title={`امکان دریافت 10% Refund به ازای هر کد معرف`}>
                            <LazyLoadImage
                              src={`/image/icon/team 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                        <PartneringBenefitCard title={`برداشت سود هر 15 روز یکبار`}>
                            <LazyLoadImage
                              src={`/image/icon/hourglass 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                        <PartneringBenefitCard title={`40% رشد حساب تا سقف 1 میلیون دلار`}>
                            <LazyLoadImage
                              src={`/image/icon/coins-upwards 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                        <PartneringBenefitCard className={'text-sm'} title={`بازگشت 20% مبلغ چالش مرحله دوم و 80% برداشت اول`}>
                            <LazyLoadImage
                              src={`/image/icon/paper-dollar 1.png`}
                              alt={'fastyoufund'}
                              effect="blur"
                              width={122}
                              className={`object-contain top-0 left-0`}
                            />
                        </PartneringBenefitCard>
                    </div>
                </div>
            </section>
        </>
    )

}


const PartneringBenefitCard = ({children, title , className}: {className?:string, children: ReactNode, title: string }) => {
    return (
        <>
            <div className={`w-full xs:w-1/2 md:!w-4/12 p-6`}>
            <div className={`bg-white px-6 py-14 h-full text-black text-center svg-center rounded-2xl  ${className??''}`}>
                {children}
                <h5 className={`text-center font-bold mt-2`}>{title}</h5>
            </div>
            </div>
        </>
    )
}