
export const About = () => {
    document.querySelector('body')!.classList.add('bg-gradient-fade');
    return (
        <>
            <div className={`w-full mx-auto lg:container pb-12`}>
                <div className={`my-12 flex lg:px-24  md:px-16 sm:px-10 m-auto items-center  flex-wrap flex-row bg-circle-double-fade before:left-1/4 after:left-1 before:opacity-25 after:opacity-25 justify-center`}>
                    <h1 className={`h1 font-bold mb-4 w-full px-12 text-center sm:hidden`}>درباره ما</h1>
                    <div className={`w-full sm:w-7/12  order-2 sm:order-1 px-12 text-justify space-y-1`}>
                        <h1 className={`h1 font-bold mb-4 w-full hidden sm:block`}>درباره ما</h1>
                        <p>
                            به CoreTrades24 خوش آمدید! ما در CoreTrades24 افتخار می‌کنیم که به عنوان یکی از پیشروان در
                            ارائه خدمات پراپ تریدینگ، معامله‌گران با استعداد را از سراسر جهان جذب و حمایت می‌کنیم.
                        </p>
                        <h3 className={'font-bold'}>ماموریت ما</h3>
                        <p>
                            ماموریت ما در CoreTrades24 ساده است: ایجاد یک محیط پویا و پشتیبانی‌کننده که معامله‌گران
                            بتوانند توانایی‌های خود را به حداکثر برسانند و به اهداف مالی خود دست یابند. ما بر این باوریم
                            که با ارائه منابع مالی مناسب و آموزش‌های لازم، هر معامله‌گری می‌تواند موفقیت‌های چشمگیری کسب
                            کند.
                        </p>
                        <h3 className={'font-bold'}>ارزش‌های ما</h3>
                        <p>
                            - شفافیت: ما در CoreTrades24 به شفافیت در همه امور پایبند هستیم. از روش‌های معاملاتی تا
                            فرایندهای پشتیبانی، همه چیز در اینجا شفاف و روشن است.
                            - پشتیبانی: تیم ما همیشه آماده است تا به شما کمک کند. از آموزش‌های تخصصی گرفته تا
                            راهنمایی‌های روزانه، ما در کنار شما هستیم.
                            - نوآوری: ما دائماً به دنبال راه‌های جدید و نوآورانه برای بهبود خدماتمان هستیم. فناوری‌های
                            پیشرفته و روش‌های معاملاتی مدرن در CoreTrades24 همیشه در دسترس شماست.
                        </p>
                        <h3 className={'font-bold'}>چرا CoreTrades24؟</h3>
                        <p>
                            - سرمایه اولیه: ما سرمایه اولیه مورد نیاز برای معاملات شما را فراهم می‌کنیم تا شما بتوانید
                            بدون نگرانی از کمبود منابع، به معاملات خود بپردازید.
                        </p>
                        <p>
                            - آموزش و مشاوره: دوره‌های آموزشی پیشرفته و جلسات مشاوره با متخصصان این حوزه به شما کمک
                            می‌کند تا مهارت‌های خود را بهبود ببخشید.
                        </p>
                        <p>
                            - پلتفرم‌های پیشرفته: ما دسترسی به پلتفرم‌های معاملاتی پیشرفته را برای شما فراهم می‌کنیم تا
                            بتوانید به بهترین نحو ممکن به معاملات خود بپردازید.
                        </p>
                        <p>
                            ما در CoreTrades24 به آینده مالی شما اهمیت می‌دهیم و آماده‌ایم تا در این مسیر پرچالش همراه
                            شما باشیم. به ما بپیوندید و امروز سفر خود را به سوی موفقیت آغاز کنید!
                        </p>
                    </div>
                    <div className={'mb-8 md:mb-0 sm:w-5/12 w-8/12 order-1 sm:order-2 '}>
                        <img className={`rounded-3xl shadow-2xl`} src={`/image/about.png`}  alt={''}/>
                    </div>
                </div>
            </div>
        </>
    )
}

