import {router} from "../../../Constant/routes";
import {NavLink} from 'react-router-dom';
import {SidebarItem} from "../Shared/SidebarItem";

function SideBar(){
    return (
        <>
            <div className={' bg-white flex flex-col sticky top-12 rounded-xl min-w-[200px]'} style={{minHeight: 'calc(100vh - 11rem)'}}>
                <div className="max-w-7xl  h-full">
                    <ul className={'mt-4 flex flex-col  justify-start px-3 leading-10 h-full'}>
                        <li className={'flex justify-center'}>
                           <NavLink to={router.customerPanelLink.package.list} className={'bg-black text-white border border-[#0781FE] p-4 rounded-3xl font-medium text-sm'}>
                             {/*<span className={`sm:hidden lg:block`}>  چالش جدید را شروع کنید</span>*/}
                               <span className={``}>شروع چالش جدید</span>
                           </NavLink>
                        </li>
                        <SidebarItem to={router.customerPanelLink.dashboard} label={'داشبورد'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 9.10937V14.8794C3 16.9994 3 16.9994 5 18.3494L10.5 21.5294C11.33 22.0094 12.68 22.0094 13.5 21.5294L19 18.3494C21 16.9994 21 16.9994 21 14.8894V9.10937C21 6.99937 21 6.99937 19 5.64937L13.5 2.46937C12.68 1.98937 11.33 1.98937 10.5 2.46937L5 5.64937C3 6.99937 3 6.99937 3 9.10937Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>
                        <SidebarItem to={router.customerPanelLink.accounts.list} label={'اکانت ها'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.02 5.97C2.75 7.65 2 9.74 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>
                        <SidebarItem to={router.customerPanelLink.downloads.list} label={'دانلود ها'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 11.5098L12 14.5098L15 11.5098" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 14.5098V6.50977" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>
                        <SidebarItem to={router.customerPanelLink.transaction.list} label={'تراکنش ها'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2617 15.998H9.26172" stroke="#7C7C80" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.66 2.51814L12.63 2.58814L9.72996 9.31814H6.87996C6.19996 9.31814 5.54996 9.45814 4.95996 9.70814L6.70996 5.52814L6.74996 5.42814L6.81996 5.26814C6.83996 5.20814 6.85996 5.14814 6.88996 5.09814C8.19996 2.06814 9.67996 1.37814 12.66 2.51814Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.0505 9.51758C17.6005 9.37758 17.1205 9.31758 16.6405 9.31758H9.73047L12.6305 2.58758L12.6605 2.51758C12.8105 2.56758 12.9505 2.63758 13.1005 2.69758L15.3105 3.62758C16.5405 4.13758 17.4005 4.66758 17.9205 5.30758C18.0205 5.42758 18.1005 5.53758 18.1705 5.66758C18.2605 5.80758 18.3305 5.94758 18.3705 6.09758C18.4105 6.18758 18.4405 6.27758 18.4605 6.35758C18.7305 7.19758 18.5705 8.22758 18.0505 9.51758Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.71 5.52734L4.96 9.70734C3.22 10.4573 2 12.1873 2 14.1973V11.2673C2 8.42734 4.02 6.05734 6.71 5.52734Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>
                        <SidebarItem to={router.customerPanelLink.tickets.list} label={'پشتیبانی'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2617 15.998H9.26172" stroke="#7C7C80" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.66 2.51814L12.63 2.58814L9.72996 9.31814H6.87996C6.19996 9.31814 5.54996 9.45814 4.95996 9.70814L6.70996 5.52814L6.74996 5.42814L6.81996 5.26814C6.83996 5.20814 6.85996 5.14814 6.88996 5.09814C8.19996 2.06814 9.67996 1.37814 12.66 2.51814Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.0505 9.51758C17.6005 9.37758 17.1205 9.31758 16.6405 9.31758H9.73047L12.6305 2.58758L12.6605 2.51758C12.8105 2.56758 12.9505 2.63758 13.1005 2.69758L15.3105 3.62758C16.5405 4.13758 17.4005 4.66758 17.9205 5.30758C18.0205 5.42758 18.1005 5.53758 18.1705 5.66758C18.2605 5.80758 18.3305 5.94758 18.3705 6.09758C18.4105 6.18758 18.4405 6.27758 18.4605 6.35758C18.7305 7.19758 18.5705 8.22758 18.0505 9.51758Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.71 5.52734L4.96 9.70734C3.22 10.4573 2 12.1873 2 14.1973V11.2673C2 8.42734 4.02 6.05734 6.71 5.52734Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>
                        <SidebarItem to={router.customerPanelLink.package.list} label={'پکیج ها'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2617 15.998H9.26172" stroke="#7C7C80" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.66 2.51814L12.63 2.58814L9.72996 9.31814H6.87996C6.19996 9.31814 5.54996 9.45814 4.95996 9.70814L6.70996 5.52814L6.74996 5.42814L6.81996 5.26814C6.83996 5.20814 6.85996 5.14814 6.88996 5.09814C8.19996 2.06814 9.67996 1.37814 12.66 2.51814Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.0505 9.51758C17.6005 9.37758 17.1205 9.31758 16.6405 9.31758H9.73047L12.6305 2.58758L12.6605 2.51758C12.8105 2.56758 12.9505 2.63758 13.1005 2.69758L15.3105 3.62758C16.5405 4.13758 17.4005 4.66758 17.9205 5.30758C18.0205 5.42758 18.1005 5.53758 18.1705 5.66758C18.2605 5.80758 18.3305 5.94758 18.3705 6.09758C18.4105 6.18758 18.4405 6.27758 18.4605 6.35758C18.7305 7.19758 18.5705 8.22758 18.0505 9.51758Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.71 5.52734L4.96 9.70734C3.22 10.4573 2 12.1873 2 14.1973V11.2673C2 8.42734 4.02 6.05734 6.71 5.52734Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z" stroke="#7C7C80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </SidebarItem>

                        <li className={'sideBarItem mt-auto mb-8'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.3203 14.62L9.76031 12.06L12.3203 9.5" stroke="#EF4A69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M20 12.0605H9.83" stroke="#EF4A69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 4C7.58 4 4 7 4 12C4 17 7.58 20 12 20" stroke="#EF4A69" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <a href="#" className="menuItem mr-0.5">خروج</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SideBar;