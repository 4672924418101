import {Route, Routes} from "react-router-dom";
import LayoutCustomer from "./Component/Layout/LayoutCustomer";
import NotFound from "../TNTFramwork/Components/Pages/NotFound";
import {ContextCustomerPanel} from "../TNTFramwork/Config/Context/ContextCustomerPanel";
import Dashboard from "./Modules/Dashboard/Pages/Dashboard";
import {RouterTicket} from "./Modules/Ticket/Routes/RouterTicket";
import {router} from "../Constant/routes";
import {RouterDownload} from "./Modules/Download/Routes/RouterDownload";
import {RouterSetting} from "./Modules/Setting/RouterSetting";
import {RouterTransaction} from "./Modules/Transaction/Routes/RouterTransaction";
import {RouterPackage} from "./Modules/Package/RouterPackage";
import {BackPayment} from "./Pages/BackPayment";
import {RouterAccounts} from "./Modules/Accounts/RouterAccounts";

function RouterCustomerPanel() {
    return (
        <ContextCustomerPanel>
            <LayoutCustomer>
                <Routes >
                    <Route>
                        <Route index element={<Dashboard />} />
                        <Route path={router.routerConfig.customerPanel.tickets.base} element={<RouterTicket />} />
                        <Route path={router.routerConfig.customerPanel.transaction.base} element={<RouterTransaction />} />
                        <Route path={router.routerConfig.customerPanel.setting.base} element={<RouterSetting />} />
                        <Route path={router.routerConfig.customerPanel.downloads.base} element={<RouterDownload />} />
                        <Route path={router.routerConfig.customerPanel.package.base} element={<RouterPackage />} />
                        <Route path={router.routerConfig.customerPanel.backPayment.base} element={<BackPayment />} />
                        <Route path={router.routerConfig.customerPanel.accounts.base} element={<RouterAccounts />} />

                        <Route path={'*'} element={<NotFound />} />
                    </Route>
                </Routes>
            </LayoutCustomer>
        </ContextCustomerPanel>
    )
}

export default RouterCustomerPanel