import {NavLink, useParams} from "react-router-dom";

export const HeaderLogo = () => {
    const {locale} = useParams()
    return (
        <>
            <NavLink to={`/${locale}/`}  className={`logo-header flex justify-center items-center`}>
                <img src={'/image/logoCoreTrades.png'} className={`inline-block mb-[5px]`} width={200} height={80} alt={`core trades 24`}/>
            </NavLink>
        </>
    )
}
