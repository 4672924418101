import {IPaginate} from "./Interfaces";

export function classNames(...classes: (string | undefined | null | false)[]) {
    return classes.filter(Boolean).join(" ");
}


export function createSelectPageList(paginationData: IPaginate){
    let links;
    links = paginationData.links.filter(item => item.label !== "&laquo; قبلی");
    links = links.filter(item => item.label !== "بعدی &raquo;");
    return links
}