import SwiperCore, { Autoplay} from "swiper";
import { FC, Fragment, useEffect, useState } from "react";
import { dateFormatter } from "../../../TNTFramwork/Helper/DateHelper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

SwiperCore.use([Autoplay]);
import "swiper/swiper.min.css";
import { getAPI } from "../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";


export interface UserReview {
  text?: string;
  created_at: Date;
  name: string;
}

export const UserReviewSection: FC = () => {

  const [data, setData] = useState<UserReview[]>([]);

  const load = async () => {
    await getAPI("/user-comments/get9", "", false).then((response) => {
      setData(response.data.data);
    });
  };
  useEffect(() => {
    load();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: perView(),
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <>
      {data && data.length &&
        <section className={`user-review-section py-20 md:px-12 lg:px-24`}>
          <h2 className={"font-bold text-center text-[3rem] mb-20"}>بشنوید مردم در مورد ما چه میگویند!</h2>
          {
            data.length > 0 &&
            <Fragment key={"review"}>
              <Slider className={`w-full [&>div]:h-full [&>div>div]:h-full [&>div>div>div>div]:h-full`} {...settings}>
                {
                  data.map((item, index) => (
                    <div className={"px-2"} key={index}>
                      <UserReviewCard {...item} />
                    </div>
                  ))
                }
              </Slider>
              {/*<div className={`relative  py-4 md:px-24`}>*/}
              {/*  <Swiper className={`items-stretch`}*/}
              {/*          modules={[Navigation]}*/}
              {/*          spaceBetween={25}*/}
              {/*          slidesPerView={perView()}*/}
              {/*          speed={200}*/}
              {/*          loop={true}*/}
              {/*          touchRatio={1.5}*/}
              {/*          navigation={{*/}
              {/*            nextEl: ".swiper-button-next",*/}
              {/*            prevEl: ".swiper-button-prev"*/}
              {/*          }}*/}
              {/*          effect={"flip"}*/}
              {/*    // pagination={{clickable: true}}*/}
              {/*          autoplay*/}
              {/*  >*/}
              {/*    {*/}
              {/*      data.map((item, index) => (*/}
              {/*        <SwiperSlide key={`swiper_${index}`} className={`h-full`}>*/}
              {/*          <UserReviewCard {...item} />*/}
              {/*        </SwiperSlide>*/}
              {/*      ))*/}
              {/*    }*/}
              {/*  </Swiper>*/}
              {/*  <div*/}
              {/*    className="swiper-navigation absolute items-center justify-between flex h-full top-0 left-0 w-full">*/}
              {/*    <div className="swiper-button-next cursor-pointer select-none"><SVGArrow /></div>*/}
              {/*    <div className="swiper-button-prev cursor-pointer select-none"><SVGArrow className={`rotate-180`} />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </Fragment>
          }
        </section>
      }
    </>
  );
};

const UserReviewCard = (data: UserReview) => {
  return (
    <div
      className={`card bg-white h-full rounded-2xl m-2 max-w-[300px] h-[290px] mx-auto shadow border-1 border-solid border-gray-300 px-6 py-8 text-justify`}
      key={Math.random()}>
      <p>{data.text}</p>
      <div className={`flex flex-wrap mt-4 justify-between flex-row`}>
        <p className={`text-gray-500 text-sm`}>{dateFormatter(data.created_at)}</p>
        <p className={"text-sm"}>{data.name}</p>
      </div>
    </div>
  );
};


export const perView = () => {
  const width = window.innerWidth;
  if (width < 420) {
    return 1;
  } else if (width < 768) {
    return 1;
  } else if (width < 1600) {
    return 3;
  } else {
    return 4;
  }

};

