import {ReactNode, useState} from 'react';
import SideBar from "./SideBar";
import NotFound from "../../../TNTFramwork/Components/Pages/NotFound";
import {getGlobalContext} from "../../../TNTFramwork/Config/Context/GlobalContext";
import Header from "./Header";
import CustomerPanelBackground from "../svg/CustomerPanelBackground";
import {Drawer} from "@material-tailwind/react";

interface LayoutCustomerProps {
    children: ReactNode;
}

const LayoutCustomer = ({children}: LayoutCustomerProps) => {
    const error404 = getGlobalContext().variable.notfound;
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    return (
        <>
            {error404 ? (
                <NotFound/>
            ) : (
                <>
                    <div className={'relative bg-[#F7F7F7]'}>
                        <div className={'absolute inset-0 w-full h-full z-20 overflow-hidden'}>
                            <CustomerPanelBackground/>
                        </div>
                        <div className={'relative z-40 '}>
                            <Header setIsOpenDrawerMenu={(value) => setIsOpenDrawer(value)}/>
                            <div className={'mx-[20px] md:mx-[80px] pt-6 min-h-[calc(100vh-75px)]'}>
                                <div className={'flex flex-row flex-wrap lg:container mx-auto min-h-full'}>
                                    <div className={'hidden md:block md:w-1/5 lg:w-2/12'}>
                                        <SideBar/>
                                    </div>
                                    <div className={'w-full md:w-4/5 lg:w-10/12 md:pr-10 min-h-full'}>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Drawer
                            placement="right"
                            open={isOpenDrawer}
                            onClose={() => setIsOpenDrawer(false)}
                            className="p-4"
                        >
                            <div className="mt-3 mb-6 flex items-center justify-center">
                                <div className={'flex items-center'}>
                                    <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'} height={'39px'}/>
                                    <h1 className={'px-2 text-black text-xl font-extrabold uppercase'}>Core Trades</h1>
                                </div>
                            </div>
                            <div className={'w-full border-2 border-gray-300'}></div>
                            <SideBar/>
                        </Drawer>
                    </div>
                </>
            )}
        </>
    );
};

export default LayoutCustomer;