import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {router} from "../../../../Constant/routes";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";


export function LoginStepThree(props: any) {
    const navigate = useNavigate();
// eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const handleSetInformation = async (event: any) => {
        setLoading(true)
        event.preventDefault()
        try {
            const response = await postAPI('/loginStepThree', {email: props.email, firstName, lastName, password ,repeatPassword }, setLoading);
            if (response.status == 200) {
                navigate(router.customerPanelLink.dashboard)
            }
        } catch (e) {
            console.error(e)
        }
    }
    // const handleStep = (event: any) => {
    //     event.preventDefault()
    //     props.goToStep(1)
    // }
    const handleFirstName = (e: any) => {
        setFirstName(e.target.value)
    }
    const handleLastName = (e: any) => {
        setLastName(e.target.value)
    }
    const handlePassword = (e: any) => {
        setPassword(e.target.value)
    }
    const handleRepeatPassword = (e: any) => {
        setRepeatPassword(e.target.value)
    }
    return (
        <>
            <div className={'w-full'}>
                <div className={'text-white md:text-gray-600 flex '}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="currentColor" className="w-8 h-8 md:hidden">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                    <div className={'flex items-center'}>
                        <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'}
                             height={'39px'}/>
                        <h1 className={'px-2 text-black font-extrabold uppercase'}>Core Trades</h1>
                    </div>
                </div>

                <div className={'mt-10 text-[#292D34]'}>
                    <span className={'text-xl font-bold leading-5'}>تکمیل اطلاعات</span>
                </div>
                <div className={'mt-3 text-[#000000B2]'}>
                    <span className={'text-sm font-normal leading-5'}>{`شما با ایمیل ${props.email} وارد شدید`}</span>
                </div>
                {/*<div className={'mt-8 mx-6'}>*/}
                {/*    <label className={'text-sm font-semibold text-gray-900'}>ایمیل ثبت شده:</label>*/}
                {/*    <TUInput label={'ایمیل وارد شده'} value={props.email} disabled={true}/>*/}
                {/*    /!*<div className={'flex justify-center pt-2'}>*!/*/}
                {/*    /!*    <button className={'border rounded-full border-gray-600'} onClick={handleStep}><span className={'text-sm p-3'}>تغییر ایمیل</span></button>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*</div>*/}
                <div className={'mt-8 mx-6 space-y-6'}>
                    <TUInput label={'نام'} onChange={handleFirstName}/>
                    <TUInput label={'نام خانوادگی'} onChange={handleLastName}/>
                    <TUInput label={'رمز عبور'} onChange={handlePassword} type={'password'}/>
                    <TUInput label={'تکرار رمز عبور'} onChange={handleRepeatPassword} type={'password'}/>
                </div>

                <div className={'flex justify-center mx-16 mt-8'}>
                    <button
                        onClick={handleSetInformation}
                        className={'flex justify-center items-center rounded-full border-2 w-full py-6 bg-black'}>
                        <span className={'text-white text-base font-bold'}>ادامه</span>
                    </button>
                </div>
            </div>
        </>
    )
}