import React, { ChangeEvent, KeyboardEvent, useState, useRef, useEffect } from 'react';

interface SerialInputProps {
  defaultValue?: string;
  inputsClassName?: string;
  className?: string;
  onChange?: (value: string) => void;
  dir?: 'ltr' | 'rtl';
  inputSize?: number
}

const SerialInput: React.FC<SerialInputProps> = ({
                                                   defaultValue = '',
                                                   onChange = () => null,
                                                   dir = 'ltr',
                                                   inputsClassName = '',
                                                   className = '',
                                                   inputSize= 4
                                                 }) => {
  const [values, setValues] = useState<string[]>(Array(inputSize).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    const valuesFromDefault = Array.from({ length: inputSize }, (_, i) => defaultValue[i] || '');
    setValues(valuesFromDefault);
  }, [defaultValue]);



  const handleKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && e.currentTarget.value === '') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === '' || /^[0-9]$/.test(val)) {
      const newValues = [...values];
      newValues[index] = val;
      setValues(newValues);
      onChange(newValues.join(''));

      if (val !== '' && index < values.length - 1) {
        setTimeout(() => inputRefs.current[index + 1]?.focus(), 0);  // Using setTimeout to ensure focus change
      }
    }
  };

  const handleFocus = (index: number) => () => {
    if (values[index] === '' && index !== 0) {
      const lastFilledIndex = values.slice(0, index).lastIndexOf('') - 1;
      if (lastFilledIndex >= 0) {
        setTimeout(() => inputRefs.current[lastFilledIndex]?.focus(), 0); // Using setTimeout to ensure focus change
      } else if (values[0] === '') {
        setTimeout(() => inputRefs.current[0]?.focus(), 0); // Using setTimeout to ensure focus change
      }
    }
  };

  const inputElements = values.map((val, index) => (
    <input
      key={`input-${index}`}
      type="text"
      ref={ref => inputRefs.current[index] = ref}
      value={val}
      onChange={handleChange(index)}
      onKeyDown={handleKeyDown(index)}
      onFocus={handleFocus(index)}
      maxLength={1}
      className={`m-2 w-14 h-14 text-center text-xl border border-gray-400 rounded transition-all duration-200 ease-in-out transform hover:scale-105 focus:scale-105
      ${inputsClassName}`}
      dir={dir}
    />
  ));

  return (
    <div className={`flex ${dir === 'rtl' ? 'flex-row' : 'flex-row-reverse'} ${className}`}>
      {inputElements}
    </div>
  );
};

export default SerialInput;
