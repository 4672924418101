import { ReactNode } from "react";
import { HeaderSite } from "../components/Header/HeaderSite";
import "../../styles/site/index.scss";
import { FooterBig } from "../components/Footer/FooterBig";

interface LayoutCustomerProps {
  children: ReactNode;
}

const LayoutSite = ({ children }: LayoutCustomerProps) => {
  return (
    <>
      <div className={`wrapper  h-[100vh]`}>
        <div className={"[direction:rtl]"}>
          <HeaderSite />
          {children}

          <FooterBig />
        </div>
      </div>

    </>
  );
};

export default LayoutSite;