import {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from "react-helmet";

export const ContactUs = () => {

    return (
        <>
            <section className={`introduce-section relative mx-auto pb-10 mt-[12vh]`}>
                <div className={`flex justify-center flex-wrap w-full`}>
                    <div className={`hero w-full pt-6 flex justify-center absolute  z-[-1]`}>
                        <div className={`hero-circle w-fit `}>
                            <Helmet>
                                <link rel="preload" as="image" href="/image/site/home/gradient.webp" />
                            </Helmet>
                            <LazyLoadImage
                              src={`/image/site/home/gradient.webp`}
                              alt={'core trades 24'}
                              effect="blur"
                              width={660}
                              height={660}
                              className={`invisible rounded-full max-h-[100vh] max-w-[90%] lg:max-w-[700px]`}
                            />
                        </div>
                    </div>
                    <div className={`w-full`}>
                        <h1 className={`h1 font-bold mb-6 text-center`}>ارتباط با ما</h1>

                        <div className={`bg-black flex flex-wrap flex-row py-16 md:px-24 justify-evenly`}>
                            <ContactCard className={`w-full md:w-4/12 `}>
                                <h4 className={`h4 mb-6 font-bold`}>با ما تماس بگیرید</h4>
                                <nav className={`text-blue-600 svg-inline [direction:ltr] text-left`}>
                                    <NavLink className={'block mb-8'} to={`mailto:support@coretrades24.com`} target={'_blank'}>Email: support@coretrades24.com</NavLink>
                                    <NavLink className={'block mb-8'} to={`https://t.me/zahraabedi63`} target={'_blank'}>Telegram: @zahraabedi63</NavLink>
                                </nav>
                            </ContactCard>
                            <ContactCard className={`w-full md:w-4/12 `}>
                                <h4 className={`h4 mb-6 font-bold`}>نشانی</h4>
                                <h6 className={`h6 mb-2 mt-4 font-bold`}>انگلیس</h6>
                                <p className={'text-sm text-gray-600'} dir={'ltr'}>
                                    Units 4609-4614, 46/F, COSCO Tower, 183 Queen's Road Central, HK
                                </p>
                                <p className={'text-sm text-gray-600'} dir={'ltr'}>
                                    Tel: (852) 2214 4101
                                </p>
                            </ContactCard>

                            <div className={`w-full md:w-4/12 flex flex-col flex-wrap justify-between`}>
                                <ContactCard className={`w-full`}>
                                <h4 className={`h4 mb-6 font-bold`}>ساعات کاری</h4>
                                    <p className={'text-sm text-gray-600'}>ما فقط یک کلیک فاصله داریم می توانید تماس بگیرید
                                        ما 24/7 از طریق ایمیل یا تیکت، چت زنده فقط برای مشتریان VIP قابل مشاهده است</p>
                                </ContactCard>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}


const ContactCard = ({children, className}: { children: ReactNode, className?: string }) => {

    return (
        <>
            <div className={`px-6 ${className ?? ''}`}>
                <div className={`bg-white  rounded-3xl p-8 `}>
                    {children}
                </div>
            </div>
        </>
    )
}