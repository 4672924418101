import {Route, Routes} from 'react-router-dom';
import NotFound from "../../../../TNTFramwork/Components/Pages/NotFound";
import TicketList from "../Pages/TicketList";
import {router} from "../../../../Constant/routes";
import {TicketNew} from "../Pages/TicketNew";
import {TicketConversation} from "../Pages/TicketConversation";

export function RouterTicket(){
    return (
        <Routes>
            <Route index element={<TicketList />} />
            <Route path={router.routerConfig.customerPanel.tickets.conversation} element={<TicketConversation />} />
            <Route path={router.routerConfig.customerPanel.tickets.new} element={<TicketNew />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
