import {NavLink} from "react-router-dom";
import {ReactNode} from "react";

export const BtnCTA = ({children, to, text, className, onClick}: { children?: ReactNode, to: string, text?: string, className?: string, onClick?: any }) => {
    return (
        <>
            <NavLink to={to} className={`bg-black text-white px-3 py-2  lg:px-6 lg:py-4 rounded-3xl  text-sm inline-block ${className}`}
                     onClick={onClick}>
                {children ?
                    children : <span>{text}</span>
                }
            </NavLink>
        </>
    )
}