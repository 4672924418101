import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";

export const ModuleServices = {
    // eslint-disable-next-line
    async changePassword(data: any) {
        const url = `/changePassword`;
        return await postAPI(url , data)
    },
    // eslint-disable-next-line
    async updateInformation(data: any) {
        const url = `/update`;
        return await postAPI(url , data)
    },


};