import {cloneElement, useState} from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {LoginStepOne} from "./LoginStepOne";
import {LoginStepTwo} from "./LoginStepTwo";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {LoginStepThree} from "./LoginStepThree";
import {LoginStepPassword} from "./LoginStepPassword";

export function BaseStepper () {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const goToNextStep = () => setStep(step + 1);

    const goToStep = (stepFlow: number) => {
        if (stepFlow){
            setStep(stepFlow)
        }else {
            setStep(step + 1)
        }
    };

    const goToPreviousStep = () => {
        const stepFlow = step - 1
        setStep(stepFlow)
        if (stepFlow === 1){
            setEmail('')
        }
    };

    const steps = [
        { id: 1, component: <LoginStepOne goToNextStep={goToNextStep} parentSetEmail={setEmail} goToStep={goToStep}/> },
        { id: 2, component: <LoginStepTwo goToNextStep={goToNextStep} email={email} goToPreviousStep={goToPreviousStep} goToStep={goToStep}/> },
        { id: 3, component: <LoginStepThree goToNextStep={goToNextStep} email={email} goToPreviousStep={goToPreviousStep} goToStep={goToStep}/> },
        { id: 4, component: <LoginStepPassword email={email} goToPreviousStep={goToPreviousStep} goToStep={goToStep}/> },
    ];

    return (
        <>
            <GoogleOAuthProvider clientId="717988703164-q8skr6hubg5t1qnl4su8l94m5mhe6ioo.apps.googleusercontent.com">

            <div className={'relative flex flex-wrap h-screen bg-white overflow-y-hidden'}>
                <div className={'flex w-full sm:flex-grow sm:w-full md:w-1/2'}>
                        <div className="flex flex-grow-[1] items-center justify-center relative mt-20 mx-16">
                        <TransitionGroup>
                            {steps
                                .filter(stepItem => stepItem.id === step)
                                .map(stepItem => (
                                    <CSSTransition
                                        key={stepItem.id}
                                        timeout={300}
                                        classNames="item"
                                        appear
                                        unmountOnExit
                                    >
                                        <div className={'absolute w-full left-0 top-0'}>
                                            {cloneElement(stepItem.component, { key: stepItem.id })}
                                        </div>
                                    </CSSTransition>
                                ))}
                        </TransitionGroup>
                    </div>
                </div>
                <div className={'hidden sm:flex-grow sm:w-full md:flex md:w-1/2 shrink-0 relative'}>
                    <img src={'/image/loginBackGround.png'}
                         className={'object-contain absolute z-10 top-0 right-0'}/>
                    <img src={'/image/dashboardImage.png'}
                         className={'mt-[20%] h-[calc(100%-20%)] absolute z-20 left-0'}/>
                </div>
            </div>
            </GoogleOAuthProvider>


        </>
    )
}