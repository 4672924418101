
const CustomerPanelBackground = () => {
    return (
        <svg width="100%" height="1263" viewBox="0 0 1440 1263" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" filter="url(#filter0_f_305_1978)">
                <rect x="222" y="60" width="203" height="255" rx="101.5" fill="#5347DC" fillOpacity="0.6"/>
            </g>
            <g opacity="0.5" filter="url(#filter1_f_305_1978)">
                <rect x="1428" y="548" width="203" height="255" rx="101.5" fill="#5347DC" fillOpacity="0.6"/>
            </g>
            <g opacity="0.5" filter="url(#filter2_f_305_1978)">
                <rect x="-22" width="203" height="288" rx="101.5" fill="#2D58ED" fillOpacity="0.2"/>
            </g>
            <g opacity="0.5" filter="url(#filter3_f_305_1978)">
                <rect x="1184" y="488" width="203" height="288" rx="101.5" fill="#2D58ED" fillOpacity="0.2"/>
            </g>
            <g opacity="0.5" filter="url(#filter4_f_305_1978)">
                <rect x="-9" y="254" width="263" height="245" rx="122.5" fill="#7DDDE4"/>
            </g>
            <g opacity="0.5" filter="url(#filter5_f_305_1978)">
                <rect x="1197" y="742" width="263" height="245" rx="122.5" fill="#7DDDE4"/>
            </g>
            <defs>
                <filter id="filter0_f_305_1978" x="42" y="-120" width="563" height="615" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="90" result="effect1_foregroundBlur_305_1978"/>
                </filter>
                <filter id="filter1_f_305_1978" x="1248" y="368" width="563" height="615" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="90" result="effect1_foregroundBlur_305_1978"/>
                </filter>
                <filter id="filter2_f_305_1978" x="-122" y="-100" width="403" height="488" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_305_1978"/>
                </filter>
                <filter id="filter3_f_305_1978" x="1084" y="388" width="403" height="488" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_305_1978"/>
                </filter>
                <filter id="filter4_f_305_1978" x="-285" y="-22" width="815" height="797" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_305_1978"/>
                </filter>
                <filter id="filter5_f_305_1978" x="921" y="466" width="815" height="797" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_305_1978"/>
                </filter>
            </defs>
        </svg>

    )
}

export default CustomerPanelBackground