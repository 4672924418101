import {PlanTable} from "../Shared/Plan/PlanTable";
import { LegacyRef } from "react";


export const PlanSection = ({refID}: { locale: string, refID: LegacyRef<HTMLDivElement> | undefined }) => {
    return (
        <>
            <section className={`plan-section flex-wrap flex flex-row justify-center bg-gradient-fade`}>
                <div className={`plan- px-4 sm:px-0 justify-between flex-wrap  flex-col sm:flex-row flex w-full md:w-11/12 lg:w-10/12  border-gray-400 border-b-2 border-dashed mb-8 pb-8`}>
                    <h2 className={`h2 sm:w-3/5 flex-grow-[3]  sm:text-[3rem] font-bold`}>مدل تامین مالی برتر را برای خود انتخاب کنید</h2>
                </div>
                <div className={`w-full md:w-11/12 lg:w-10/12 border border-[#0000001a] border-solid rounded-3xl shadow p-6`} ref={refID}>
                    <PlanTable headerClass={'bg-[#f9f9fc]'}/>
                </div>
            </section>
        </>
    )
}