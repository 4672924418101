const customerPanelPrefix = '/customer-panel';
const langPrefix = '/:locale/';


export const router = {
    routerConfig: {
        customerPanel: {
            base: '/customer-panel/*',
            login: '/login',
            forgotPassword: '/forgot-password',
            tickets: {
                base: '/tickets/*',
                conversation: '/:id/conversation',
                new: '/new',
            },
            package: {
                base: '/package/*',
                checkout: '/:id/checkout',
            },
            transaction: {
                base: '/transaction/*',
            },
            setting: {
                base: '/setting/*',
                personalInformation: '/personal-information',
            },
            downloads: {
                base: '/downloads/*',
            },
            backPayment: {
                base: '/backpayment',
            },
            accounts: {
                base: '/accounts/*',
            },
        },
        site: {
            base: `${langPrefix}/*`,
            contactUs: '/contactus',
            blogs: `/blog-list`,
            blogCategory: `/blog-list/:categorySlug`,
            blogDetail: `/blog/:slug`,
            blogSearch: `/search`,
            faq:'/faq',
            terms:'/terms',
            about:'/about-us',
        }
    },
    customerPanelLink: {
        dashboard: `${customerPanelPrefix}/`,
        login: `/login`,
        forgotPassword: `/forgot-password`,
        tickets: {
            list: `${customerPanelPrefix}/tickets`,
            conversation: (id: string) => `${customerPanelPrefix}/tickets/${id}/conversation`,
            new: `${customerPanelPrefix}/tickets/new`,
        },
        transaction: {
            list: `${customerPanelPrefix}/transaction`,
        },
        accounts: {
            list: `${customerPanelPrefix}/accounts`,
        },
        package: {
            list: `${customerPanelPrefix}/package`,
            checkout: (id: string) => `${customerPanelPrefix}/package/${id}/checkout`,
        },
        setting: {
            personalInformation: `${customerPanelPrefix}/setting/personal-information`,
        },
        downloads: {
            list: `${customerPanelPrefix}/downloads`,
        },
        backPayment: {
            base: `${customerPanelPrefix}/backpayment`,
        },
    },
    siteLink: {
        home: ``,
        contact: `/contactus`,
        blog: {
            search: `/search`,
            list: `/blog-list/`,
            listCategorySlug: (slug: string) => `/blog-list/${slug}`,
            details: (slug:string) =>  `/blog/${slug}`
        },
        faq:'/faq',
        terms:'/terms',
        about:'/about-us',
    }
};