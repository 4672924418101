import {Input} from "@material-tailwind/react";
import React from "react";
import { Controller } from 'react-hook-form';


interface TUInputProps {
    name?: string;
    label: string;
    onChange?: (...args: any[]) => any;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    containerProps?: string;
    labelProps?: string;
    value?: string;
    type?: 'text' | 'password',
    control?: any,

}

export const TUInput = ({onChange = () => console.log("onChange not provided"), type = 'text',control = null, ...TUInputProps}: TUInputProps) => {
    return (
        <>
            {control ? (
                <Controller
                    name={TUInputProps.name ?? TUInputProps.label}
                    control={control}
                    render={({ field , fieldState: {error}}) => (
                        <>
                            <div>
                                <Input
                                    {...field}
                                    label={TUInputProps.label}
                                    className={` focus:!shadow-none ${TUInputProps.className ?? ``}`}
                                    containerProps={{ className: TUInputProps.containerProps ?? `` }}
                                    labelProps={{ className: TUInputProps.labelProps ?? `` }}
                                    disabled={TUInputProps.disabled ?? false}
                                    readOnly={TUInputProps.readonly ?? false}
                                    type={type}
                                    // style={{border: '#DC2626' , color: '#DC2626'}}
                                />
                                {error && error.message &&(
                                    <span className="text-red-900 font-semibold text-xs">{error.message}</span>
                                )}
                            </div>
                        </>
                    )}
                />
            ) : (
                <Input
                    label={TUInputProps.label}
                    className={`focus:!shadow-none ${TUInputProps.className ?? ``}`}
                    containerProps={{ className: TUInputProps.containerProps ?? '' }}
                    labelProps={{ className: TUInputProps.labelProps ?? '' }}
                    value={TUInputProps.value}
                    disabled={TUInputProps.disabled ?? false}
                    readOnly={TUInputProps.readonly ?? false}
                    onChange={onChange}
                    type={type}
                />
            )}
        </>
    )
}


