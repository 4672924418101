import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";
import React, {useEffect} from "react";
import {Resolver, useForm} from "react-hook-form";
import {
    FormFieldsDefaultValueUserInformation,
    FormFieldsUserInformation,
    FormFieldsUserInformationValidationSchema,
} from "../Config/InterfaceSetting";
import {yupResolver} from "@hookform/resolvers/yup";
import {ModuleServices} from "../Services/Services";
import {showSuccess} from "../../../../TNTFramwork/Helper/helper";
import {useGlobalContext} from "../../../../TNTFramwork/Config/Context/GlobalContext";

export const PersonalInformation = () => {
    const { config} = useGlobalContext();
    const { user } = config;

    const methods = useForm<FormFieldsUserInformation>({
        resolver: yupResolver(FormFieldsUserInformationValidationSchema) as Resolver<FormFieldsUserInformation>,
        defaultValues: FormFieldsDefaultValueUserInformation
    });

    const {handleSubmit, reset, control} = methods;
    useEffect(() => {
        reset({
            'firstName' : user.firstName,
            'lastName' : user.lastName,
            'phone' : user.phone,
        })
    }, []);
    const onSubmit = async (data: any) => {
        delete data.repeatPassword
        const response = await ModuleServices.updateInformation(data)
        if (response.status === 200){
            showSuccess(response.data.message)
        }else {
            console.log(response)
        }
    }
    return(
        <div className={'userPanelContainer overflow-x-hidden h-full'}>
            <div className={'p-10 h-full '}>
                <form onSubmit={handleSubmit(onSubmit)} className={'relative h-full space-y-4'}>
                    <div className={'flex flex-row justify-center items-center'}>
                        <div className={'w-6/12'}>
                            <TUInput label={'آدرس ایمیل'} value={user.email} readonly={true} />
                        </div>
                    </div>
                    <div className={'grid grid-auto-fit gap-4'}>
                        <TUInput label={'نام'} name={'firstName'} control={control} />
                        <TUInput label={'نام خانوادگی'} name={'lastName'} control={control}/>
                    </div>
                    <div className={'grid grid-auto-fit gap-4'}>
                        <TUInput label={'شماره همراه'} name={'phone'} control={control}/>
                        <TUInput label={'کد معرف'} value={user.referralCode} readonly={true}/>
                    </div>

                    <button className={'absolute bottom-0 bg-background-black rounded-full w-[344px] h-[72px]'}>
                        <span className={'p-6 text-white text-base font-normal'}>ویرایش اطلاعات</span>
                    </button>
                </form>
            </div>
        </div>
    )
}