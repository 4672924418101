import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import Toastify from "./TNTFramwork/Components/Toastify";
import GlobalContextProvider from "./TNTFramwork/Config/Context/GlobalContext";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <GlobalContextProvider>
      <BrowserRouter>
        <Toastify />
        <App />
      </BrowserRouter>
  </GlobalContextProvider>
);
