// import { Hello } from "tenet-ui";
import CustomInput from "./CustomInput";

export const TenetInput = () => {
  return(
    <>
      {/*<Hello name={'majid'}/>*/}

      <div className={'p-64'}>
        <div className={'mx-auto w-72 space-y-10'} dir={'rtl'}>
          <CustomInput label={'test Input Function TNT'}/>
        </div>
      </div>
    </>
  )
}