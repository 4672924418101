import React, {useContext, useState} from "react";


interface customerPanelLayout {
    config:{
        headerTitle: string
    }
}
let setContextCustomerPanel: React.Dispatch<React.SetStateAction<customerPanelLayout>>

const Context = React.createContext<any>(undefined as any)


const ContextCustomerPanel = ({children}: { children: React.ReactNode }) =>{
    const [config, setConfig] = useState<customerPanelLayout>({
        config:{
            headerTitle: 'سرویس های من'
        }
    });
    const pageContext = {config, setConfig};
    setContextCustomerPanel = setConfig
    return (
        <Context.Provider value={pageContext}>
            {children}
        </Context.Provider>
    )
}


export default ContextCustomerPanel;

export {setContextCustomerPanel};
export const useContextCustomerPanel = () => useContext(Context);

export {ContextCustomerPanel}

export const getCustomerPanelContext = () => {
    const {config} = useContextCustomerPanel();
    return config
}