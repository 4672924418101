
export const FooterBig = () => {
    return (
      <footer className={"bg-black w-full px-14 pt-8 pb-10"}>
        <div className={'md:flex md:gap-16 pb-16 items-center'}>
          {/*<img src={'/image/logoFullWhite.png'} alt={'Core Trades logo full white'} width={323} />*/}
          <img src={'/image/logoCoreTrades.png'} className={`inline-block mb-[5px]`} width={200}
               alt={`core trades 24`} />
          <p className={'pt-4 md:pt-0 text-white text-base font-normal leading-8 text-center '}>
            CoreTrades24 پیشرو در ارائه خدمات پراپ تریدینگ در ایران. ما معامله‌گران با استعداد را از سراسر جهان جذب و حمایت می‌کنیم و همواره آماده‌ایم تا در این مسیر پرچالش همراه شما باشیم. به ما بپیوندید و امروز سفر خود را به سوی موفقیت آغاز کنید!
          </p>
        </div>
        <div className={'container'}>
          <div className={'row'}>
            {/*<div className={'pb-16 md:pb-0 sm:col-12 md:col-6 gap-20'}>*/}
            {/*  <div className={'row'}>*/}
            {/*    <div className={"col"}>*/}
            {/*      <LazyLoadImage*/}
            {/*        src={`/image/enamad.jpg`}*/}
            {/*        alt={'FUF Enamad'}*/}
            {/*        effect="blur"*/}
            {/*        className={`object-contain top-0 left-0`}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    <div className={"col"}>*/}
            {/*      <LazyLoadImage*/}
            {/*        src={`/image/samandehi.jpg`}*/}
            {/*        alt={'FUF Samandehi'}*/}
            {/*        effect="blur"*/}
            {/*        className={`object-contain top-0 left-0`}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={"sm:col-12 md:col-6 text-white"}>*/}
            {/*  <div className={'row'}>*/}
            {/*    <div className={"col-6 md:col-4"}>*/}
            {/*      <p className={'pb-1 md:pb-2'}>پیوندها</p>*/}
            {/*      <div className={'space-y-1'}>*/}
            {/*        <p className={'cursor-pointer'}>مقالات</p>*/}
            {/*        <p className={'cursor-pointer'}>درباره ما</p>*/}
            {/*        <p className={'cursor-pointer'}>ارتباط با ما</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"hidden md:block  col-4"}>*/}
            {/*      <p className={'pb-1 md:pb-2'}>لینک های مهم</p>*/}
            {/*      <div className={'space-y-1'}>*/}
            {/*        <p className={'cursor-pointer'}>کانال تلگرام</p>*/}
            {/*        <p className={'cursor-pointer'}>ثبت نام و ورود</p>*/}
            {/*        <p className={'cursor-pointer'}>پلن ها</p>*/}
            {/*        <p className={'cursor-pointer'}>صفحه اصلی</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"col-6 md:col-4"}>*/}
            {/*      <p className={'pb-1 md:pb-2'}>ما را دنبال کنید</p>*/}
            {/*      <div className={'space-y-1'}>*/}
            {/*        <p className={'cursor-pointer'}>فیسبوک</p>*/}
            {/*        <p className={'cursor-pointer'}>توییتر</p>*/}
            {/*        <p className={'cursor-pointer'}>اینستاگرام</p>*/}
            {/*        <p className={'cursor-pointer'}>یوتوب</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={'sm:col-12 md:col-12 text-white text-center'}>
              © 2023 Core Trades 24 Prop Trading.
            </div>
          </div>
        </div>

      </footer>
    )
}

