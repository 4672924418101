import {SVGSearchIcon} from "../SVG/SVGDollar";
import {useParams} from "react-router-dom";
import {LinkNavigator} from "../Shared/LinkNavigator";
import {ICategoryBlog, Link} from "../../Config/interfaces/BlogInterface";
import {router} from "../../../Constant/routes";
import {getQueryString} from "../../../TNTFramwork/Helper/helper";

export const BlogHeader = ({category}: { category: ICategoryBlog[] }) => {
    const query = getQueryString('query')
    const {locale} = useParams()
    const links: Link[] = category.map(x => {
        return {
            url:`/${locale}${router.siteLink.blog.listCategorySlug(x.slug)}`,
            label : x.name,
        }
    })



    return (
        <>
            <div className={`flex flex-wrap flex-row mt-2`}>
                <div className={`w-full md:w-4/12 px-2 mb-2`}>
                     <form action={`/${locale}${router.siteLink.blog.search}`} method={'get'} className={`input-group-icon flex flex-row items-center px-6 py-3 rounded-3xl bg-gray-200 border border-1 border-gray-400`}>
                         <button className={'bg-transparent border-none outline-none'} type='submit'> <SVGSearchIcon/></button>
                        <input name={`query`} placeholder={query ?? `جستجوی مقاله`}  className={`bg-transparent mr-6 outline-none w-full `}/>
                    </form>
                </div>
                <div className={`w-full md:w-8/12 px-2 mb-2`}>
                    <LinkNavigator items={links}/>
                </div>
            </div>
        </>
    )
}
