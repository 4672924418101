
export function FarsiToEnglishNumbers(number: number | string | undefined) {
    if (number === undefined || number === null) return '';
    const numString = number.toString();
    return numString.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d).toString());
}

export function PriceToNumberFormatter(price: number | string | undefined) {
    if (price === null || price === undefined) return '';
    return price.toString().replace(/\D/g, '');
}

export function SeparateCommaFormatter(number: number | string | undefined) {
    if (!number) return '';
    const englishNumber = FarsiToEnglishNumbers(number);
    const numberPrice = PriceToNumberFormatter(englishNumber);
    const numberParsed = parseFloat(numberPrice);
    if (isNaN(numberParsed)) return '';
    return new Intl.NumberFormat().format(numberParsed);
}
