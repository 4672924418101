import React, {useEffect, useState} from "react";
import TableReactTable from "../../../../TNTFramwork/Components/Shared/TableReactTable";
import {PaginationSimple} from "../../../../TNTFramwork/Components/Shared/Pagination/PaginationSimple";
import {IPaginate} from "../../../../TNTFramwork/Components/Shared/Pagination/Interfaces";
import {ModuleServices} from "../Services/Services";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";


function List() {
    const [row, setRow] = useState([])
    const [paginateData, setPaginateData] = useState<IPaginate>()
    const [TableLoading, setLoading] = useState(true)
    const load = async () => {
        await ModuleServices.getList().then((response) => {
            setRow(response.data.data.data)
            setPaginateData(response.data.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        load()
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "ردیف",
                accessor: "row",
                disableSortBy: true
            },
            {
                Header: "نام پلن",
                accessor: "package",
            },
            {
                Header: 'پلتفرم',
                accessor: "platform",
                disableSortBy: true
            },
            {
                Header: 'کد پیگیری',
                accessor: "refID",
                disableSortBy: true,
                Cell: ({cell}: { cell: any }) => {
                    return(
                        <span>{cell.row.original.refID ?? ' - '}</span>
                    )
                }
            },
            {
                Header: "وضعیت",
                accessor: "isSuccess",
                Cell: ({cell}: { cell: any }) => {
                    return(
                        <span className={`${cell.row.original.isSuccess ? 'text-green-800' : 'text-red-800'}`}>{(cell.row.original.isSuccess) ? 'پرداخت شده' : 'ناموفق'}</span>
                    )
                }
            },
            {
                Header: "تاریخ",
                accessor: "created_at",
            },
        ],
        []
    );

    return (
        <>
            <div className={'userPanelContainer overflow-x-hidden'}>
                <div className={'userPanelContainerHeader'}>
                    <span className={'font-bold text-lg'}>تراکنش ها</span>
                </div>
                <div className={'py-2 px-6 border-t-2 border-b-2'}>
                    <div className={'flex flex-wrap items-center gap-6'}>
                        <div className={'flex items-center w-full sm:w-1/2 md:w-1/3 flex-grow flex-shrink-0 gap-6'}>
                            <div className={'w-full sm:w-1/2 md:w-1/3 flex-grow flex-shrink-0'}>
                                <TUInput label={'جستجو'} className={''}/>
                            </div>
                        </div>
                    </div>
                </div>
                <TableReactTable columns={columns} data={row} TableLoading={TableLoading}
                                 emptyDataImageProp={'/image/NoTransaction.png'}
                    // tableTitle={'لیست سرویس های من'}
                >
                    {(paginateData) && (
                        <PaginationSimple paginationData={paginateData} setRow={setRow}
                                          setPaginateData={setPaginateData}/>
                    )}
                </TableReactTable>
            </div>
        </>
    )
}

export default List