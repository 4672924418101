import {Route, Routes} from 'react-router-dom';
import NotFound from "../../../TNTFramwork/Components/Pages/NotFound";
import {router} from "../../../Constant/routes";
import {Setting} from "./Pages/Setting";

export function RouterSetting(){
    return (
        <Routes>
            <Route path={router.routerConfig.customerPanel.setting.personalInformation} element={<Setting />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
