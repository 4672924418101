import {useGlobalContext} from "../../../TNTFramwork/Config/Context/GlobalContext";
import {showAvatar} from "../../../TNTFramwork/Helper/helper";
import { Menu, Transition } from '@headlessui/react';
import {Fragment} from "react";
import {NavLink} from "react-router-dom";
import {router} from "../../../Constant/routes";

function Header({ setIsOpenDrawerMenu }: { setIsOpenDrawerMenu: (value: boolean) => any }) {
    // const handleClickDropDown = () => {
    //     const elem = document.activeElement;
    //     if(elem){
    //         // @ts-ignore
    //         elem?.blur();
    //     }
    // };
    const { config} = useGlobalContext();
    const { user } = config;

    return (
        <>
            <div className={'top-0 md:px-[80px] px-[20px] bg-white flex justify-between items-center h-[75px]'}>
                <div className={'mr-1 text-white md:text-gray-600 flex md:hidden'}>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" strokeWidth="1.5" onClick={() => setIsOpenDrawerMenu(true)}
                         stroke="black" className="w-8 h-8 md:hidden fill-black">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                </div>
                <div className={'mr-1 text-white md:text-gray-600 flex'}>

                    <div className={'flex items-center'}>
                        <img src={'/image/logo.png'} alt={'Core Trades'} width={'39px'} height={'39px'}/>
                        <h1 className={'px-2 text-black font-extrabold uppercase'}>Core Trades</h1>
                    </div>
                </div>

                <div className={'flex items-center ml-1 text-white md:text-gray-600'}>
                    <Menu as="div" className="relative inline-block text-left z-50">
                        <div>
                            <Menu.Button className="flex items-center">
                                <span className="hidden md:block text-sm font-medium text-black px-2">{user.name}</span>
                                <img src={showAvatar(user.avatar)} className="rounded-full w-[45px] h-[45px]" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={router.customerPanelLink.setting.personalInformation}
                                                className={`${
                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                                                } flex justify-between w-full px-4 py-2 text-sm`}
                                            >
                                                ویرایش اطلاعات
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={router.customerPanelLink.transaction.list}
                                                className={`${
                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                                                } flex justify-between w-full px-4 py-2 text-sm`}
                                            >
                                                تراکنش های من
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={`${
                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                                                } flex justify-between w-full px-4 py-2 text-sm`}
                                            >
                                               تیکت های من
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={router.customerPanelLink.accounts.list}
                                                className={`${
                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                                                } flex justify-between w-full px-4 py-2 text-sm`}
                                            >
                                                اکانت های من
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={router.customerPanelLink.login}
                                                className={`${
                                                    active ? "bg-gray-100 text-red-900" : "text-red-900"
                                                } flex justify-center w-full px-4 py-2 text-sm border-t-2`}
                                            >
                                                خروج
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    {/*<button className={'mr-[23px]'}>*/}
                    {/*    <AlertSVG />*/}
                    {/*</button>*/}
                </div>
            </div>
        </>
    )
}

export default Header;