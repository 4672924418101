import {BtnCTA} from "../Shared/BtnCTA";
import {router} from "../../../Constant/routes";
import {useGlobalContext} from "../../../TNTFramwork/Config/Context/GlobalContext";


export const HeaderCallToAction = () => {
    const { config} = useGlobalContext();
    const { user } = config;
    return (
        <>
            <nav className={`c2a-header text-center md:text-right`}>
                {user.email !== '' ?
                    <BtnCTA to={router.customerPanelLink.dashboard}  text={`مشاهده داشبورد`} className={`mx-auto w-full py-4`} />
                    :
                    <BtnCTA to={router.customerPanelLink.login}  text={`ورود و ثبت نام`} className={`mx-auto w-full py-4`} />
                }
            </nav>
        </>
    )
}