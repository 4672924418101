import {Route, Routes} from 'react-router-dom';
import NotFound from "../../../TNTFramwork/Components/Pages/NotFound";
import List from "./Pages/List";
import {router} from "../../../Constant/routes";
import {Checkout} from "./Pages/Checkout";

export function RouterPackage(){
    return (
        <Routes>
            <Route index element={<List />} />
            <Route path={router.routerConfig.customerPanel.package.checkout} element={<Checkout />} />

            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
