import axios from 'axios'
import {customerBaseURL} from "../../../CustomerPanel/API/EndPoint/GlobalEndpoint";
import {showError} from "../../Helper/helper";



interface Params {
    method: string
}
export const baseConfig = () => {
    return {
        baseUrl: customerBaseURL,
        withCredentials: true,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            // 'x-xsrf-token' : `${decodeURIComponent(getCookie('XSRF-TOKEN')!)}`,
            'lang' : `fa`,
        },
    }
}

export const postConfig: Params = {
    method: 'post'
}
const getConfig : Params = {
    method: 'get'
}
const putConfig : Params = {
    method: 'put'
}

export const postAPI = async (url: string, data: any , loading?: (val: boolean) => void): Promise<any> => {
    if (loading){
        loading(true);
    }
    return await axios({
        ...baseConfig(),
        ...postConfig,
        url: `${baseConfig().baseUrl}${url}`,
        data,
    }).then((response) => {
        if (loading){
            loading(false);
        }
        return {
            status: response.status,
            data: response.data,
            message: response.data.message,
        };
    }).catch((error) => {
        if (loading){
            loading(false);
        }
        showError(error.response.data.message);
    });
};


export const putAPI = async (url: string, data: any , loading?: (val: boolean) => void): Promise<any> => {
    if (loading){
        loading(true);
    }
    return await axios({
        ...baseConfig(),
        ...putConfig,
        url: `${baseConfig().baseUrl}${url}`,
        data,
    }).then((response) => {
        if (loading){
            loading(false);
        }
        return {
            status: response.status,
            data: response.data,
            message: response.data.message,
        };
    }).catch((error) => {
        if (loading){
            loading(false);
        }
        showError(error.response.data.message);
    });
};

export const getAPI = async (url: string, params: any = {}, isShowErrorToast = true): Promise<any> => {

    return await axios({
        ...baseConfig(),
        ...getConfig,
        url: `${baseConfig().baseUrl}${url}`,
        params,
    }).then((response) => {
        return {
            status: response.status,
            data: response.data,
        };
    }).catch((error) => {
        if (isShowErrorToast){
            showError(error.response.data.message);
        }
        return error.response
    });
};

export const getAPIFullURL = async (url: string, params: any = {}): Promise<any> => {

    return await axios({
        ...baseConfig(),
        ...getConfig,
        url: `${url}`,
        params,
    }).then((response) => {
        return {
            status: response.status,
            data: response.data,
        };
    }).catch((error) => {
        showError(error.response.data.message);
        return error.response
    });
};