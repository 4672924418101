import { useMatch, NavLink } from 'react-router-dom';

export const SidebarItem = ({ to, label, children }: { to:any, children:any, label:string }) => {
    const match = useMatch(to);
    const classNames = match ? "menuItem mr-0.5 text-black font-bold" : "menuItem mr-0.5";

    return (
        <li className="sideBarItem mt-2">
            <div className={`${(match) ? 'activeSVG' : 'deActiveSVG'}`}>
                {children}
            </div>
            <NavLink to={to} className={classNames}>{label}</NavLink>
        </li>
    )
}