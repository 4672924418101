import React from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import {PersonalInformation} from "./PersonalInformation";
import {ChangePassword} from "./ChangePassword";

export const Setting = () => {
    const [activeTab, setActiveTab] = React.useState("personalInfo");
    const data = [
        {
            label: "اطلاعات شخصی",
            value: "personalInfo",
            component: <PersonalInformation />,
        },
        {
            label: "تغییر رمز عبور",
            value: "changePassword",
            component: <ChangePassword />,
        },

    ];
    return (
        <div className={'tab-full-h h-full'}>
            <Tabs value={activeTab} id={activeTab}>
                <TabsHeader
                    className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                    indicatorProps={{
                        className: "bg-transparent border-b-2 border-blue-500 shadow-none rounded-none",
                    }}
                >
                    {data.map(({ label, value }) => (
                        <Tab
                            key={value}
                            value={value}
                            onClick={() => setActiveTab(value)}
                            className={activeTab === value ? "text-blue-500" : ""}
                        >
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, component }) => (
                        <TabPanel key={value} value={value} className={'h-full'}>
                            {component}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </div>
    )
}