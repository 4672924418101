import {Menu} from "./Menu";
import {HeaderLogo} from "./HeaderLogo";
import {HeaderCallToAction} from "./HeaderCallToAction";
import {getGlobalContext} from "../../../TNTFramwork/Config/Context/GlobalContext";
import {SVGMenu} from "../SVG/SVGMenu";
import React, {useState} from "react";
import {Drawer} from "@material-tailwind/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const HeaderSite = () => {
    const context = getGlobalContext()
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const openDrawer = () => setIsOpenDrawer(true);
    const closeDrawer = () => setIsOpenDrawer(false);

    return (
        <>
            <header id={'header'} className={` border-b-2 border-solid border-gray-200 md:border-0 px-4 sm:px-0 w-full z-10 relative py-5 md:py-8 ${context.site.menuMode == "black" ? 'bg-black text-white' : 'bg-white md:bg-transparent'}`}>
                <div className={`flex justify-between px-4 items-center lg:container mx-auto  menu-responsive sm:hidden`}>
                    <HeaderLogo/>
                    <SVGMenu className={``} onClick={openDrawer}/>
                </div>
                <div className={`hidden sm:flex justify-between px-2 lg:px-4 items-center lg:container mx-auto  relative`}>
                    <HeaderLogo/>
                    <Menu/>
                    <HeaderCallToAction/>
                </div>
            </header>
            <Drawer open={isOpenDrawer} onClose={closeDrawer} size={window.innerWidth}>
                <div className={'flex justify-between shadow py-6 px-4 items-center'}>
                    <LazyLoadImage
                      src={`/image/logoCoreTrades.png`}
                      alt={"Core Trades Logo"}
                      effect="blur"
                      width={150}
                      className={`object-contain top-0 left-0`}
                    />
                    <svg width="27" height="15" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={''}
                        onClick={closeDrawer}
                    >
                        <path d="M21.3574 19.7718L3.73415 2.14852C3.33273 1.7471 3.3204 1.09366 3.70696 0.707107C4.09351 0.320555 4.74695 0.332884 5.14837 0.734303L22.7716 18.3576C23.1731 18.759 23.1854 19.4124 22.7988 19.799C22.4123 20.1855 21.7588 20.1732 21.3574 19.7718Z" fill="#292D32"/>
                        <path d="M23.0189 1.8955L5.39559 19.5188C4.99417 19.9202 4.34073 19.9325 3.95418 19.546C3.56763 19.1594 3.57995 18.506 3.98137 18.1046L21.6046 0.481288C22.0061 0.0798676 22.6595 0.0675395 23.0461 0.454091C23.4326 0.840643 23.4203 1.49408 23.0189 1.8955Z" fill="#292D32"/>
                    </svg>
                </div>
                <div className={`px-4 pb-4 flex flex-col justify-between h-[calc(100vh-85px)]`}>
                    <Menu closeDrawer={closeDrawer}/>
                    <HeaderCallToAction/>
                </div>
            </Drawer>
        </>
    )
}




