import React, {createContext, useState, useContext, ReactNode} from "react";
import {IConfig, IGlobalContext, IUser} from "./InterfaceGlobalContext";

let setGlobalContext: React.Dispatch<React.SetStateAction<IConfig>>


export const GlobalContext = createContext<IGlobalContext>(undefined as any);


const GlobalContextProvider = ({children}: { children: ReactNode }) => {
    const [config, setConfig] = useState<IConfig>({
            user: {
                name: "",
                email: "",
                phone: "",
                balance: "",
                avatar: "",
            },
            lang: 'fa',
            theme: {
                rtl: true,
            },
            variable: {
                notfound: false
            },
            site: {
                menuMode: "white"
            }
        }
    );
    setGlobalContext = setConfig
    const updateUser = (newUser: IUser) => {
        setConfig((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user, ...newUser
            },
        }));
    };
    return (
        <GlobalContext.Provider value={{config, updateUser, setConfig}}>
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalContextProvider;

export {setGlobalContext};
export const useGlobalContext = () => useContext(GlobalContext);


export const getGlobalContext = () => {
    const {config} = useGlobalContext();
    return config
}