import {useParams} from "react-router-dom";
import { useEffect, useState} from "react";
import {BlogCard} from "../components/Blog/BlogCard";
import {BlogHeader} from "../components/Blog/BlogHeader";
import {getAPI} from "../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {ICategoryBlog, IDBBlog, IResponseBlog} from "../Config/interfaces/BlogInterface";
import {getQueryString} from "../../TNTFramwork/Helper/helper";

export const BlogList = () => {
    const {categorySlug} = useParams()

    const [category, setCategory] = useState<ICategoryBlog[]>([])
    const [blogData, setBlogData] = useState<IDBBlog>()
    const load = async () => {
        const query = getQueryString('query')
        let url = '/blog/blogs'
        if (categorySlug)
            url += `?category=${categorySlug}`
        else if(query)
            url += `?search=${query}`

        await getAPI(url, '', false).then((response) => {
            const responseData: IResponseBlog = response.data.data
            setCategory(responseData.Categories)
            setBlogData(responseData.db)
        })
    }

    useEffect(() => {
        load()
    }, [categorySlug])

    // // temp
    // const blogData: IBlogCard[] = [
    //     {slug: 'test', publishDate: new Date(), title: 'خلاصه بازار Prop (6 مه - 12 مه)', image: '/image/dashboardImage.png'},
    //     {slug: 'test', publishDate: new Date(), title: 'خلاصه بازار Prop (6 مه - 12 مه)', image: '/image/dashboardImage.png'},
    //     {slug: 'test', publishDate: new Date(), title: 'خلاصه بازار Prop (6 مه - 12 مه)', image: '/image/dashboardImage.png'},
    //     {slug: 'test', publishDate: new Date(), title: 'خلاصه بازار Prop (6 مه - 12 مه)', image: '/image/loginBackGround.png'},
    //     {slug: 'test', publishDate: new Date(), title: 'خلاصه بازار Prop (6 مه - 12 مه)', image: '/image/loginBackGround.png'},
    // ]

    document.querySelector('body')!.classList.add('bg-gradient-fade');

    return (
        <>
            <div className={`w-full mx-auto lg:container`}>
                <nav className={``}>
                    <BlogHeader category={category}/>
                </nav>
                <div className={`my-12 flex flex-wrap flex-row bg-circle-fade-single-blue justify-center`}>
                    {blogData && blogData.data.length > 0 && blogData.data.map((x, index) => (
                        <div key={index} className={`w-full xs:w-10/12 sm:!w-1/2 md:!w-1/3 `}>
                            <BlogCard key={`blog_${index}`} {...x} />
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

