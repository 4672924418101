import {IntroduceSection} from "../components/HomePage/IntroduceSection";
import {useParams} from "react-router-dom";
import {BenefitSection} from "../components/HomePage/BenefitSection";
import {EvaluationSection} from "../components/HomePage/EvaluationSection";
import {PlatformSection} from "../components/HomePage/PlatformSection";
import {PlanSection} from "../components/HomePage/PlanSection";
import {PartneringBenefitSection} from "../components/HomePage/PartneringBenefitSection";
import {UserReviewSection} from "../components/HomePage/UserReviewSection";
import {useRef} from "react";

export const HomePage = () => {
    const {locale} = useParams()
    const PlanSectionRef = useRef<HTMLDivElement | null>(null);

    const scrollToPlanSection = () => {
        if (PlanSectionRef.current) {
            PlanSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className={`home-page`}>
                <IntroduceSection locale={locale!} scrollToPlanSection={scrollToPlanSection}/>
                <BenefitSection locale={locale!} />
                <EvaluationSection locale={locale!} scrollToPlanSection={scrollToPlanSection}/>
                <PlatformSection locale={locale!} />
                <PlanSection locale={locale!} refID={PlanSectionRef}/>
                <PartneringBenefitSection />
                <UserReviewSection />
            </div>
        </>
    )
}