import {setCustomerPanelHeader} from "../../../../TNTFramwork/Helper/helper";
import React from "react";
import {PlatformCard} from "../../../Component/Shared/PlatformCard";


export function DownloadList() {
    setCustomerPanelHeader('دانلود ها')

    return (
        <>
            <div>
                <div className={'pb-6'}>
                    <span className={'text-black font-semibold leading-5 '}>Meta Trader</span>
                </div>
                <div className={'flex flex-wrap justify-evenly'}>

                    <PlatformCard
                     className={''}
                     title={'سیستم عامل ویندوز'}
                     platforms={[
                         { link: '#', label: ' MT4'},
                         { link: '#', label: ' MT5'}
                     ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل اندروید'}
                        platforms={[
                            { link: '#', label: ' MT4'},
                            { link: '#', label: ' MT5'}
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل IOS'}
                        platforms={[
                            { link: '#', label: ' MT4'},
                            { link: '#', label: ' MT5'}
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'وب ترمینال'}
                        platforms={[
                            { link: '#', label: ' MT4'},
                            { link: '#', label: ' MT5'}
                        ]}
                    />
                </div>
            </div>
            <div>
                <div className={'py-6 '}>
                    <span className={'text-black font-semibold leading-5 '}>Trading View</span>
                </div>
                <div className={'flex flex-wrap justify-evenly'}>

                    <PlatformCard
                        className={''}
                        title={'سیستم عامل ویندوز'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل اندروید'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل IOS'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'وب ترمینال'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                </div>
            </div>
            <div>
                <div className={'py-6 '}>
                    <span className={'text-black font-semibold leading-5 '}>cTrader</span>
                </div>
                <div className={'flex flex-wrap justify-evenly'}>

                    <PlatformCard
                        className={''}
                        title={'سیستم عامل ویندوز'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل اندروید'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'سیستم عامل IOS'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                    <PlatformCard
                        className={''}
                        title={'وب ترمینال'}
                        platforms={[
                            { link: '#'},
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

