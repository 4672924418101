import {getAPI, postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";

export const ModuleServices = {
    async getList() {
        const url = `/ticket/getList`;
        return await getAPI(url)
    },
    async getListDashboard() {
        const url = `/getDashboard`;
        return await getAPI(url)
    },
    async storeNewTicket(data: any) {
        const url = `/ticket/storeNewTicket`;
        return await postAPI(url , data)
    },
    async getConversation(id: string) {
        const url = `/ticket/getListConversation/${id}`;
        return  await getAPI(url)

    },


};