import {Resolver, useForm} from 'react-hook-form'
import React, {useEffect, useRef} from "react";
import {TUSelect} from "../../../../TNTFramwork/Components/Shared/Select/TailwindMaterial/TUSelect";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";
import {TUTextArea} from "../../../../TNTFramwork/Components/Shared/TextArea/TailwindMaterial/TUTextArea";
import {yupResolver} from "@hookform/resolvers/yup";
import {defaultValues, ITicketRequest, TicketSchema} from "../Config/TicketInterface";
import {ModuleServices} from "../Services/Services";
import {useNavigate} from "react-router-dom";
import {router} from "../../../../Constant/routes";


export function TicketNew() {
    const navigate = useNavigate()
    const fileInputRef = useRef<HTMLInputElement>(null); // Specify the type of fileInputRef

    const handleFileClick = () => {
        fileInputRef.current!.value = "";
        fileInputRef.current!.click();
    }
    const methods = useForm<ITicketRequest>({
        resolver: yupResolver(TicketSchema) as Resolver<ITicketRequest>,
        defaultValues: defaultValues,
    });
    const {handleSubmit, reset, setValue, control, formState: {errors}} = methods;

    useEffect(() => {
        console.log(errors)
    }, [errors])
    useEffect(() => {
        reset(defaultValues)
    }, [])
    const onSubmit = async (data: any) => {
        const response = await ModuleServices.storeNewTicket(data)
        if (response.status === 200) {
            navigate(router.customerPanelLink.tickets.list)
        } else {
            console.log(response)
        }
    }

    return (
        <>
        <div className={'userPanelContainer overflow-x-hidden'}>
            <div className={'userPanelContainerHeader'}>
                <span className={'font-bold text-lg'}>باز کردن تیکت جدید</span>
            </div>
            <div className={' -mt-3 mb-12'}>
                <p className={'font-normal text-sm px-6 text-gray-700'}>اگر سوالی دارید و به کمک ما نیاز دارید، لطفا فرم را ارسال کنید. یکی از نماینده پشتیبانی ما به زودی با شما تماس خواهد گرفت.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'py-2 px-6'}>
                    <div className={'flex flex-wrap items-center '}>
                        <div className={'w-full   lg:w-3/5 px-3 my-2'}>
                            <TUInput containerProps={'!min-w-[unset]'} label={'موضوع'} name={'title'} control={control}/>
                        </div>
                        <div className={'w-full xs:w-1/2  lg:!w-1/5 px-3 my-2'}>
                            <TUSelect label={'اولویت'}
                                      selectFirstItem={true}
                                      containerProps={'!min-w-[unset]'}
                                      control={control}
                                      items={[
                                          {value: 'low', label: 'کم'},
                                          {value: 'middle', label: 'متوسط'},
                                          {value: 'high', label: 'زیاد'},
                                      ]}
                                      name={'priority'}
                            />
                        </div>
                        <div className={'w-full xs:w-1/2 lg:!w-1/5 px-3 my-2'}>
                            <TUSelect label={'دپارتمان'}
                                      selectFirstItem={true}
                                      containerProps={'!min-w-[unset]'}
                                      control={control}
                                      items={[
                                          {value: 'sales', label: 'دپارتمان فروش'},
                                          {value: 'technical', label: 'دپارتمان فنی'},
                                          {value: 'accounting', label: 'دپارتمان مالی'},
                                          {value: 'management', label: 'دپارتمان مدیریت'},
                                      ]}
                                      name={'department'}/>
                        </div>
                </div>
                <div className={'mt-6 '}>
                    <TUTextArea label={'متن'} name={'text'} control={control}/>
                </div>
                <div className={'mt-6 flex justify-between px-4 border-2 border-dashed p-4 border-indigo-300'}>
                    <div className={'space-y-2 text-gray-600 font-normal text-sm'}>
                        <p>حجم فایل کمتر از 2048 کیلوبایت</p>
                        <p>فرمت مجاز JPEG . PNG</p>
                    </div>
                    <div onClick={handleFileClick}
                         className={'flex items-center border border-black rounded-full cursor-pointer'}>
                        <div className={'flex justify-between items-center gap-4 px-6'}>
                            <span className={'font-normal text-sm text-black'}>انتخاب فایل</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                    stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 12H16" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M12 16V8" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={e => {
                            console.log(e.target.files);
                            setValue('file', e.target.files);
                        }}
                    />

                </div>
                <div className={'mt-6 flex justify-center md:justify-start'}>
                    <button className={'flex font-bold text-white bg-[#1E1E1E] rounded-full'} type={'submit'}>
                        <div className={'p-6 w-72'}>
                            ثبت تیکت
                        </div>
                    </button>
                </div>
        </div>
        </form>
</div>
</>
)
}