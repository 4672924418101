
export const baseURL = import.meta.env.VITE_API_BASE_URL // http://localhost:8000

export const sanctumCookieURL =  baseURL + '/sanctum/csrf-cookie'
export const getAccess =  baseURL + '/api/getAccess'

export const customerBaseURL = baseURL + '/api/userPanel'

export const adminBaseURL =  baseURL + '/api/tnt_portal'

export const customerLogin =  baseURL + customerBaseURL + '/login'
export const customerLogout =  baseURL + customerBaseURL + '/logout'
export const customerGetInformation =  baseURL + customerBaseURL + '/getInformation'