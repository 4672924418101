import {Outlet} from 'react-router-dom'
import {getAPI} from "../Axios/AxiosCustomerConfig";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../Context/GlobalContext";
import {FastYouFundLoader} from "../../Components/SVG/FastYouFundLoader";
export const CustomerAuthSiteRoutes = () => {
    const {updateUser} = useGlobalContext();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        const responseGetInformation = await getAPI('/getInformation', '' , false);
        if (responseGetInformation.status === 401) {
            setLoading(false)
            return true;
        }
        const userData = responseGetInformation.data.data
        updateUser({
            name: userData.firstName + ' ' + userData.lastName,
            firstName: userData.firstName,
            lastName: userData.lastName,
            referralCode: userData.referralCode,
            email: userData.email,
            phone: userData.phone,
            balance: userData.balance,
            avatar: userData.avatar,
        })
        setLoading(false)
    };

    // useEffect(() => {
    //     const responseSanctumCookie = axios.get(sanctumCookieURL , {withCredentials: true})
    // }, [refreshToken]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);


    return (<>
            {
                (loading) ? <FastYouFundLoader/>
                    : <>
                        {
                            <Outlet/>
                        }
                    </>
            }
        </>
    );
};

