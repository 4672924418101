export const SVGSuccessChecked = ({className}: { className?: string }) => {
    return (
        <>
            <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M14.9946 8.6215C16.5134 8.50024 17.9552 7.90288 19.1146 6.91447C20.4242 5.7991 22.0882 5.18652 23.8084 5.18652C25.5286 5.18652 27.1926 5.7991 28.5021 6.91447C29.6616 7.90288 31.1034 8.50024 32.6221 8.6215C34.3373 8.75858 35.9475 9.50208 37.1642 10.7187C38.3808 11.9354 39.1243 13.5456 39.2614 15.2607C39.3818 16.7789 39.9791 18.2215 40.9684 19.3808C42.0838 20.6903 42.6964 22.3543 42.6964 24.0745C42.6964 25.7947 42.0838 27.4587 40.9684 28.7682C39.98 29.9277 39.3826 31.3695 39.2614 32.8883C39.1243 34.6034 38.3808 36.2136 37.1642 37.4303C35.9475 38.6469 34.3373 39.3904 32.6221 39.5275C31.1034 39.6488 29.6616 40.2461 28.5021 41.2345C27.1926 42.3499 25.5286 42.9625 23.8084 42.9625C22.0882 42.9625 20.4242 42.3499 19.1146 41.2345C17.9552 40.2461 16.5134 39.6488 14.9946 39.5275C13.2795 39.3904 11.6693 38.6469 10.4526 37.4303C9.23597 36.2136 8.49246 34.6034 8.35539 32.8883C8.23413 31.3695 7.63676 29.9277 6.64836 28.7682C5.53298 27.4587 4.92041 25.7947 4.92041 24.0745C4.92041 22.3543 5.53298 20.6903 6.64836 19.3808C7.63676 18.2213 8.23413 16.7795 8.35539 15.2607C8.49246 13.5456 9.23597 11.9354 10.4526 10.7187C11.6693 9.50208 13.2795 8.75858 14.9946 8.6215ZM32.5608 21.0217C32.9908 20.5764 33.2288 19.98 33.2234 19.3609C33.2181 18.7419 32.9698 18.1497 32.532 17.7119C32.0942 17.2742 31.5021 17.0259 30.883 17.0205C30.264 17.0151 29.6676 17.2531 29.2223 17.6832L21.4474 25.4581L18.3945 22.4052C17.9492 21.9752 17.3528 21.7372 16.7338 21.7426C16.1147 21.7479 15.5225 21.9963 15.0848 22.434C14.647 22.8718 14.3987 23.4639 14.3933 24.083C14.388 24.7021 14.6259 25.2985 15.056 25.7438L19.7781 30.4658C20.2209 30.9085 20.8213 31.1571 21.4474 31.1571C22.0734 31.1571 22.6738 30.9085 23.1166 30.4658L32.5608 21.0217Z"
                      fill="white"/>
            </svg>
        </>
    )
}