import React, { FC, useState, InputHTMLAttributes, useEffect } from "react";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelClassName?: string;
  containerClassName?: string;
}

const CustomInput: FC<CustomInputProps> = ({label, value: valueProp, onChange, defaultValue,
                                             className, labelClassName, containerClassName,  ...rest }) => {
  const isControlled = valueProp !== undefined;
  const [inputValue, setInputValue] = useState(isControlled ? valueProp : defaultValue || "");
  const [isFocused, setIsFocused] = useState(false);
  const [direction, setDirection] = useState("ltr");
  const hasValue = inputValue && inputValue.toString().length > 0;

  const generateSlug = (label: string) => {
    return label
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };
  const id = (rest.id) ?? generateSlug(label)
  const containerId = `container-${id}`

  useEffect(() => {
    const parentElement = document.getElementById(containerId)?.parentElement;
    if (parentElement) {
      const dir = window.getComputedStyle(parentElement).getPropertyValue('direction') || "ltr";
      setDirection(dir);
    }
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isControlled) {
      setInputValue(e.target.value);
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`relative ${containerClassName}`} dir={direction} id={`container-${id}`}>
      <input
        id={id}
        value={isControlled ? valueProp : inputValue}
        {...rest}
        onChange={handleInputChange}
        className={`block w-full px-4 py-3 rounded border-none outline-none bg-transparent relative z-[1]  ${className}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        htmlFor={id}
        id={`label-${id}`}
        className={`absolute ${direction === 'rtl' ? 'right-4' : 'left-4'} w-full h-full text-sm top-0 bottom-0 flex items-center pointer-events-none transition-all ease-out duration-150 transform
         ${hasValue || isFocused ? 'text-blue-500 text-xs -translate-y-6' : 'text-gray-500 text-base'} ${labelClassName}`}
      >
       {label}
      </label>
      
      <fieldset className={`absolute bottom-0 left-0 w-full h-[125%] border ${isFocused || hasValue ? 'border-blue-500' : 'border-gray-300'} `}>
        <legend className={`mr-2  ${isFocused || hasValue ? '':' visible:hidden whitespace-nowrap overflow-hidden max-w-[0.01px] ' }`}>
          <span className={`opacity-0 text-sm`}>{label}</span>
        </legend>
      </fieldset>
    </div>
  );
};


export default CustomInput;
