import React from "react";
import {postAPI} from "../../../../TNTFramwork/Config/Axios/AxiosCustomerConfig";
import {TUInput} from "../../../../TNTFramwork/Components/Shared/Input/TailwindMaterial/TUInput";
import { useForm } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";
import { router } from "../../../../Constant/routes";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export function ForgotPassword(props: any) {
    const schema = yup.object().shape({
        email: yup.string().email("فرمت آدرس ایمیل صحیح نیست").required("فیلد آدرس ایمیل الزامی است"),
    });
    const method = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        }
    })
    const {control, handleSubmit} = method
    const onSubmit = async (data: any) => {
        await postAPI('/forgotPassword', data);
        props.goToStep(1)
    }
    return (
      <>
          <div style={{backgroundImage: `url('/image/forgotPasswordBackground.png')`}} className={'h-[100vh] bg-cover'}>
              <div className={'text-center px-4  sm:px-8 md:px-32 lg:px-64 xl:px-96 py-24 md:py-16 lg:py-24 xl:py-32'}>
                  <LazyLoadImage
                    src={`/image/LogoFull.png`}
                    alt={"Core Trades Logo"}
                    effect="blur"
                    width={150}
                    className={`object-contain top-0 left-0`}
                  />
                  <div className={'mt-10 text-[#292D34]'}>
                      <span className={'text-xl font-bold leading-5'}>رمز عبور را فراموش کرده اید؟</span>
                  </div>
                  <div className={'mt-3 text-[#000000B2]'}>
                      <span className={'text-sm font-normal leading-5'}>برای تغییر رمز عبور آدرس ایمیل خود را ارسال کنید تا ایمیل تغییر رمز برای شما ارسال شود</span>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className={'w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto mt-8 '}>
                          <TUInput label={'آدرس ایمیل'} name={'email'} control={control}/>
                      </div>

                      <div className={'flex justify-center mt-8'}>
                          <button
                            className={'w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-full border-2 py-3 sm:py-4 md:py-5 lg:py-6 bg-black'}>
                              <span className={'text-white text-base font-bold'}>تغییر رمز عبور</span>
                          </button>
                      </div>

                      <div className={'flex justify-center mt-8'}>
                          <NavLink to={router.customerPanelLink.login} className={"text-gray-100"}>
                              <span className={'text-blue-600 text-sm'}>بازگشت به صفحه ورود</span>
                          </NavLink>
                      </div>
                  </form>
              </div>
          </div>
      </>
    )

}