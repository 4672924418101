import {Outlet, Navigate, useNavigate} from 'react-router-dom'
import {getAPI} from "../Axios/AxiosCustomerConfig";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../Context/GlobalContext";
import {router} from "../../../Constant/routes";
import {FastYouFundLoader} from "../../Components/SVG/FastYouFundLoader";

const CustomerAuthRoutes = () => {
    const {updateUser} = useGlobalContext();
    const navigate = useNavigate()
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        const responseGetInformation = await getAPI('/getInformation');
        if (responseGetInformation.status === 401) {
            navigate(router.customerPanelLink.login)
            return null;
        }
        const userData = responseGetInformation.data.data
        updateUser({
            name: userData.firstName + ' ' + userData.lastName,
            firstName: userData.firstName,
            lastName: userData.lastName,
            referralCode: userData.referralCode,
            email: userData.email,
            phone: userData.phone,
            balance: userData.balance,
            avatar: userData.avatar,
        })
        if (responseGetInformation.status === 200) {
            setAuthenticated(true);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (<>
            {
                (loading) ? <FastYouFundLoader/>
                    : <>
                        {
                            (authenticated) ? <Outlet/> : <Navigate to={router.customerPanelLink.login}/>
                        }
                    </>
            }
        </>
    );
};

export default CustomerAuthRoutes;