import {BtnCTA} from "../Shared/BtnCTA";
import {SVGCandles} from "../SVG/Candles";
import {SVGSuccessChecked} from "../SVG/SVGSuccessChecked";
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const IntroduceSection = ({locale, scrollToPlanSection}: { locale: string, scrollToPlanSection: () => void }) => {

    return (
        <>
            <section className={`introduce-section relative mx-auto pb-10 mt-[-6rem] pt-[7rem] overflow-hidden`}>
                <div className={`flex justify-center flex-wrap w-full`}>
                    <div className={`hero w-full pt-6 flex justify-center`}>
                        <div className={`hero-circle w-fit `}>
                            <Helmet>
                                <link rel="preload" as="image" href="/image/site/home/gradient.webp" />
                            </Helmet>
                            <div className="w-[400px] h-[400px] md:w-[800px] md:h-[800px] lg:w-660 lg:h-660">
                                <LazyLoadImage
                                  src={`/image/site/home/gradient.webp`}
                                  alt={'fastyoufund'}
                                  effect="blur"
                                  className="w-full h-full rounded-full object-cover"
                                />
                            </div>
                        </div>
                        <div className={`absolute bg-black z-[0] left-0 top-[65%] w-full bottom-box min-h-[21rem]`}></div>
                    </div>
                    <div className={`introduce-content absolute z-[5] top-0 w-full left-0 pt-[10rem]`}>
                        <h1 className={`h1 lg:text-[60px] md:text-[60px]  text-center font-bold`}>سرمایه گذاری حرفه ای<br/>معامله گران حرفه ای</h1>
                        {/*<h1 className={`h1 lg:text-[60px] md:text-[60px]  text-center font-bold`}> کشف دنیایی پر سود با<br/> پراپ تریدینگ </h1>*/}
                        <ul className={`list-disc m-auto text-center w-fit mt-3 text-xl md:text-md list-none`}>
                            {/*<li>جادوی سرمایه گذاری</li>*/}
                            {/*<li>سرمایه گذاری حرفه ای</li>*/}
                            {/*<li>معامله گران حرفه ای</li>*/}
                            <li> سرمایه‌گذاری – هوشمندی - سودآوری</li>
                        </ul>
                        <div className={`text-center`}>
                            <BtnCTA onClick={scrollToPlanSection} to={`/${locale}/`} className={`font-bold mb-2 sm:mb-8 my-8 text-xl px-4 py-4 `} text={`یک قدم تا شروع  !`}/>
                        </div>

                        <div className={'flex flex-wrap w-full  justify-evenly sm:justify-center sm:gap-8 items-center max-w-[900px] sm:px-5 mx-auto'}>
                            <div className={'sm:flex-grow-[3] md:flex-grow-[2] sm:max-w-[60%] max-w-[55%]'}>
                                <SVGCandles className={`backdrop-blur-[72px]`}/>
                            </div>
                            <div className={`max-w-[60%] sm:max-w-[40%] xs:flex  sm:flex-grow-[1] mr-1 text-white text-center backdrop-blur-[72px] border border-[#ffffff40] p-1 px-3 sm:px-4 sm:py-4 md:px-8 md:py-8 rounded-xl sm:rounded-3xl  items-center flex-col justify-center`}>
                                <SVGSuccessChecked className={`w-8 sm:w-[unset] mx-auto`} />
                                <h6 className={`text-[8px] sm:h6`}>پرداخت انجام شد</h6>
                                <p className={` text-[6px] sm:text-[0.75rem] mt-1`}>معامله شما با موفقیت انجام شد</p>
                            </div>
                            <div className={`w-full min-w-full text-center hidden md:block`}>
                                <div className={`flex-row m-auto inline-flex m-auto text-white text-center bg-[#00000033] backdrop-blur-[72px] border border-[#ffffff40] px-8 py-3 rounded-3xl items-center justify-center`}>
                                    <div className={`border-l border-l-[#ffffff30] p-1`}>
                                        <h3 className={`h3 font-bold inline-block mr-1`}><span className={'text-[10px]'}>واریز سود</span>  68.2k $ </h3>
                                    </div>
                                    <div className={``}>
                                        <h3 className={`h3 font-bold inline-block mr-1 p-1`}><span className={'text-[10px] '}>تریدر فعال</span> 342</h3>
                                        {/* Todo: get user count and sum with 342*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}