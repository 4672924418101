import {NavLink} from "react-router-dom";
import {SVGArrowCircle} from "../SVG/SVGArrowCircle";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// eslint-disable-next-line
export const PlatformSection = ({locale}:{locale:string})=>{

    return (
        <>
            <section className={`platform-section md:py-32 flex justify-center items-center bg-gradient-fade relative`}>

                <div className={`rounded-3xl border-2 border-solid border-white bg-[#F2F2F7] px-4  md:px-6 py-24 w-full sm:w-11/12 md:w-11/12 lg:w-10/12 bg-circle-double-fade`}>
                    <div className={`flex flex-wrap justify-between`}>
                        <div className={`w-full md:pl-6 md:w-3/6 lg:w-1/3 mb-12 md:mb-0`}>
                            <h2 className={`h3 font-bold mb-8`}>پلتفرم‌های پیشرفته معاملاتی</h2>
                            <p className={`font-bold d mb-8 leading-7 text-justify`}>
                                با متاتریدر4 و متاتریدر5، قدرت معاملاتی در دستان شماست! با رابط کاربری کارآمد و ابزارهای تحلیلی پیشرفته، به سرعت و هوشمندی معاملات خود را انجام دهید. استفاده از اسکریپت‌ها و ربات‌ها، به شما امکان می‌دهد استراتژی‌های خودکار خود را پیاده‌سازی کنید. با متاتریدر4 و متاتریدر5، شروع به معامله‌گری حرفه‌ای کنید و بهترین تصمیم‌های مالی را بگیرید.
                            </p>
                            <div className={`bg-white flex justify-evenly rounded-full shadow-[-6px_0_0_0_#635BFF,0_5px_35px_0_#d9d9d9c4] py-3 max-w-[350px] mx-auto`}>
                                <NavLink to={`/{locale}/`}>
                                    <span className={`font-bold mx-1`}>متاتریدر 4</span>
                                    <LazyLoadImage
                                      src={`/image/site/metatrader/metatrader4.png`}
                                      alt={'fastyoufund meta 4'}
                                      effect="blur"
                                      width={40}
                                      height={40}
                                      className={`inline-block max-w-[35px]`}
                                    />
                                </NavLink>
                                <NavLink to={`/{locale}/`}>
                                    <span className={`font-bold mx-1`}>متاتریدر 5</span>
                                    <LazyLoadImage
                                      src={`/image/site/metatrader/metatrader5.png`}
                                      alt={'fastyoufund meta 5'}
                                      effect="blur"
                                      width={40}
                                      height={40}
                                      className={`inline-block max-w-[35px]`}
                                    />
                                </NavLink>
                            </div>
                        </div>
                        <div className={`w-full  md:w-3/6 lg:w-1/2 flex flex-wrap md:flex-nowrap sm:gap-2 justify-evenly flex-row`}>
                            <PlatformBox login={`104120`} title={`Spread - MT4 را بررسی کنید`} password={`FN2022`} server={`EightcapLtd-Real-4`} />
                            <PlatformBox login={`104121`} title={`Spread - MT5 را بررسی کنید`} password={`FN2022`} server={`EightcapLtd-Real-5`} />
                        </div>
                    </div>
                    {/*<img src={`/image/site/home/blurCircle.png`} className={`object-contain absolute top-0 left-0 z-[-1]`} />*/}
                </div>


            </section>
        </>
    )

}

const PlatformBox= ({title,login,password , server}:{title:string,login:string,password:string , server:string})=>{
    return (
        <>
            <div className={`platform bg-white pb-12 rounded-3xl mb-12 md:mb-0 w-full xs:w-[unset] max-w-[450px]`}>
                <div className={`bg-blue-600 font-bold md:text-sm lg:text-xl mt-[-1rem] mb-4 text-white px-3 py-4 rounded-3xl text-center`}>
                    {title}
                </div>
               <div className={`px-4 text-left`}>
                   <div className={`from-group mb-3`}>
                       <span className={`text-sm font-bold mb-1  block`}>شناسه ورود</span>
                       <div className={`bg-gray-200 rounded-xl p-2 md:text-sm lg:text-normal`}>
                           {login}
                           <SVGArrowCircle  className={`inline-block mx-2 `}/>
                       </div>
                   </div>
                   <div className={`from-group mb-3`}>
                       <span className={`text-sm font-bold mb-1 block md:text-sm lg:text-normal`}>رمز ورود</span>
                       <div className={`bg-gray-200 rounded-xl p-2 md:text-sm lg:text-normal` }>
                           {password}
                           <SVGArrowCircle  className={`inline-block mx-2 `}/>
                       </div>
                   </div>
                   <div className={`from-group mb-3`}>
                       <span className={`text-sm font-bold mb-1  block `}>نام ارائه کننده</span>
                       <div className={`bg-gray-200 rounded-xl p-2 md:text-sm lg:text-normal`}>
                           {server}
                           <SVGArrowCircle  className={`inline-block mx-2 `}/>
                       </div>
                   </div>
               </div>
            </div>
        </>
    )
}