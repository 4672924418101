import {Resolver, useForm} from 'react-hook-form'
import React, {useEffect, useRef, useState} from "react";
import {TUTextArea} from "../../../../TNTFramwork/Components/Shared/TextArea/TailwindMaterial/TUTextArea";
import {yupResolver} from "@hookform/resolvers/yup";
import {defaultValues, ITicketRequest, TicketSchema} from "../Config/TicketInterface";
import {ModuleServices} from "../Services/Services";
import {useNavigate, useParams} from "react-router-dom";
import {router} from "../../../../Constant/routes";
import {useGlobalContext} from "../../../../TNTFramwork/Config/Context/GlobalContext";
import {TicketConversationUserQuestion} from "../Components/TicketConversationUserQuestion";
import {TicketConversationAdminAnswer} from "../Components/TicketConversationAdminAnswer";

interface IConversationResponse{
    ticket_title: string,
    ticket_number: string,
    status: string,
    conversations: any,
}

export function TicketConversation() {
    const { id } = useParams();
    const navigate = useNavigate()
    const fileInputRef = useRef<HTMLInputElement>(null); // Specify the type of fileInputRef
    const { config} = useGlobalContext();
    const { user } = config;
    const [data , setData] = useState<IConversationResponse>()

    const handleFileClick = () => {
        fileInputRef.current!.value = "";
        fileInputRef.current!.click();
    }
    const methods = useForm<ITicketRequest>({
        resolver: yupResolver(TicketSchema) as Resolver<ITicketRequest>,
        defaultValues: defaultValues,
    });
    const {handleSubmit, setValue, control, formState: {errors}} = methods;

   useEffect(() => {
       if (errors){
           console.log(errors)
       }
   } , [errors])


    const fetch = async () => {
       if (id){
           const response = await ModuleServices.getConversation(id)
           if (response.status === 200){
               setData(response.data.data)
           }
       }

    }

    useEffect(() => {
        fetch()
    } , [])

    const onSubmit = async (data: any) => {
       const response = await ModuleServices.storeNewTicket(data)
        if (response.status === 200){
            navigate(router.customerPanelLink.tickets.list)
        }else {
            console.log(response)
        }
    }

    return (
        <>
            <div className={'md:container bg-white rounded-3xl text-black overflow-x-hidden'}>
                <div className={'px-6 py-6'}>
                    <span className={'font-bold text-lg'}>{`تیکت ${data?.ticket_title}`}</span>
                    <div className={'mt-6 grid gap-4 grid-auto-fit'}>
                        <div className={'bg-gray-100 border-2 border-gray-300 rounded-full'}>
                            <div className={'p-4 px-8'}>
                                <p className={'text-sm font-light'}>نام</p>
                                <p className={'pr-2'}>{user.name}</p>
                            </div>
                        </div>
                        <div className={'bg-gray-100 border-2 border-gray-300 rounded-full'}>
                            <div className={'p-4 px-8'}>
                                <p className={'text-sm font-light'}>شماره تیکت</p>
                                <p className={'pr-2'}>{data?.ticket_number}</p>
                            </div>
                        </div>
                        <div className={'bg-gray-100 border-2 border-gray-300 rounded-full'}>
                            <div className={'p-4 px-8'}>
                                <p className={'text-sm font-light'}>وضعیت</p>
                                <p className={'pr-2'}>{data?.status}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'md:container bg-white rounded-3xl text-black overflow-x-hidden mt-6'}>
                <div className={'m-12 space-y-6'}>
                    {data?.conversations.map((item: any, index: any) => {
                       if (item.isAnswer){
                           return (<TicketConversationAdminAnswer text={item.text} key={index} time={item.time} admin={item.admin}/>)
                       }else {
                           return (<TicketConversationUserQuestion text={item.text} key={index} time={item.time}/>)
                       }
                    })}

                </div>


                <div className={'mt-6'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'py-2 px-6'}>

                            <div className={'mt-6 '}>
                                <TUTextArea label={'متن'} name={'text'} control={control}/>
                            </div>
                            <div className={'mt-6 flex justify-between px-4 border-2 border-dashed p-4 border-indigo-300'}>
                                <div className={'space-y-2 text-gray-600 font-normal text-sm'}>
                                    <p>حجم فایل کمتر از 2048 کیلوبایت</p>
                                    <p>فرمت مجاز JPEG . PNG</p>
                                </div>
                                <div onClick={handleFileClick}
                                     className={'flex items-center border border-black rounded-full cursor-pointer'}>
                                    <div className={'flex justify-between items-center gap-4 px-6'}>
                                        <span className={'font-normal text-sm text-black'}>انتخاب فایل</span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                                stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8 12H16" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M12 16V8" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{display: 'none'}}
                                    onChange={e => {
                                        console.log(e.target.files);
                                        setValue('file', e.target.files);
                                    }}
                                />

                            </div>
                            <div className={'mt-6 flex justify-start'}>
                                <button className={'flex font-bold text-white bg-[#1E1E1E] rounded-full'} type={'submit'}>
                                    <div className={'p-6 w-72'}>
                                        ثبت تیکت
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}