import { NavLink, useParams } from "react-router-dom";
import { router } from "../../../Constant/routes";

export const Menu = ({ closeDrawer }: { closeDrawer?: any }) => {
  const { locale } = useParams();
  const menuItem = [
    { "name": "وبلاگ", "link": `/${locale}${router.siteLink.blog.list}` },
    { "name": " تماس با ما", "link": `/${locale}${router.siteLink.contact}` },
    { "name": "درباره ما", "link": `/${locale}${router.siteLink.about}` },
    { "name": "سوالات متداول", "link": `/${locale}${router.siteLink.faq}` },
    { "name": "قوانین", "link": `/${locale}${router.siteLink.terms}` },
    { "name": "چگونه کار می کند", "link": `/${locale}` }
  ];
  if (window.innerWidth <= 768) {
    return (
      <>
        <nav className={`menu-header flex flex-col sm:flex-row text-lg font-semibold`}>
          {menuItem.map((item, index) => {
            return (
              <NavLink to={item.link} className={"border-b p-4"} key={index} onClick={closeDrawer}><span>{item.name}</span></NavLink>
            );
          })}
        </nav>
      </>
    );
  }
  return (
    <>
      <nav className={`menu-header gap-4 md:gap-[10px] lg:gap-[32px] flex flex-col sm:flex-row `}>
        {menuItem.map((item, index) => {
          return (
            <NavLink to={item.link} key={index}><span>{item.name}</span></NavLink>
          );
        })}
      </nav>
    </>
  );
};
